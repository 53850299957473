import styles from './articalFirst.module.css'
import {Share} from "../../components/Share/share";
import React from "react";
import { LeftOutline } from 'antd-mobile-icons'
import { useHistory } from 'react-router-dom'

export const ArticalThird = () => {

    const history = useHistory()

    return (
        <div className={styles.articalFirst}>
            <Share title={'健康科普丨关于身体的的35个健康常识，很多人现在还不知道'} param={'&articalId=3'}/>
            <div className={styles.title}>
                <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
                关于身体的的35个健康常识，很多人现在还不知道</div>
            {/*<div style={{ marginBottom: '16px', color: '#999' }}>西安发布 2022-08-29 00:25</div>*/}
            <div className={styles.info}>盘点关于身体心、肝、肺、眼、鼻等器官的35个健康常识，有些可能你还不了解。</div>
            <div className={styles.titleFour}>鼻子</div>
            <div className={styles.content}>
                1.一个鼻孔出气是真的。在一整天里，也总是一个鼻孔处于工作状态，另一个鼻孔处于休息状态，这被称为鼻循环。
                <br />
                2. 游泳能缓解鼻炎，也能“帮倒忙”。急性鼻炎、慢性鼻炎急性发作期应该休息；慢性鼻炎不在急性发作期可适当体育锻炼，提高抵抗力，间接地减少鼻炎发生。但一定要水质清洁、水温要合适，过冷刺激也会诱发鼻炎。
                <br />
                3. 过敏性鼻炎和哮喘，都是呼吸道常见的过敏性疾病。一个影响上气道，一个影响下气道。流行病学调查显示，鼻炎患者的哮喘发病率，较正常人高4-20 倍；80%的哮喘患者，伴有过敏性鼻炎。
            </div>
            <div className={styles.titleFour}>眼睛</div>
            <div className={styles.content}>
                1. 远视眼和老花眼是两个概念，只不过在配镜矫正时，都是用的凸透镜。老花眼是一种生理现象，很多人到了一定年纪都会出现老花眼。
                <br />
                2. 选择护眼灯，无频闪{'>'}高频闪{'>'}低频闪。台灯的色温应在4000K左右。这个色温的灯光带点黄，比较柔和，最适合阅读。
                <br />
                3. 5岁以下孩子视力还处于发育阶段，到六七岁才逐渐达到成人水平。长时间看电子屏幕不仅会导致视疲劳，还会消耗远视储备，导致孩子过早发生近视。
            </div>
            <div className={styles.titleFour}>牙齿</div>
            <div className={styles.content}>
                1. 牙齿的正常颜色本身并不是纯白色，而是淡黄色。牙釉质越透明越健康，越能透出它深层牙本质的淡黄色本色。
                <br />
                2. 牙周炎的典型表现就是刷牙或者啃硬物时出血，等到牙齿松动了，其实已经很严重了。牙齿的问题，越早治疗越有效，而且越便宜。
                <br />
                3. 药物牙膏建议在口腔医生的指导下使用。为了维护口腔健康，建议三个月左右换一次牙膏。这样既可以防止耐药性、抗药性的产生，又能够避免某些成分过多地摄入体内。
                <br />
                4. 牙齿脱落就要及时补种修复。如果没能及时补上，相邻的牙齿失去依靠，因为咀嚼作用力不均匀慢慢向中间靠拢、歪倒，最后松动、脱出。
                <br />
                5. 坚持刷牙也可能有牙结石，最好每年一次到正规医院做口腔检查，除去牙结石。
            </div>
            <div className={styles.titleFour}>肝脏</div>
            <div className={styles.content}>
                1. 丙肝只要按照规范的抗病毒治疗，就可以达到病毒清除，是完全可以根治的，不再复发。
                <br />
                2. 有调查发现部分脂肪肝相关肝癌没有肝硬化背景，也就是说这类疾病可以“跳级”，跨越肝硬化这个阶段，直接转变成肝癌。
                <br />
                3. 肝硬化逆转是有可能的，但需很长很长的时间，需很多的耐心和努力。一定要遵医嘱治疗，改善生活方式。
                <br />
                4. 经常熬夜，容易导致肝细胞供血不足，破坏原有的细胞结构，增加肝脏损伤的风险，肝功能也会因为休息不足而出现影响。
            </div>
            <div className={styles.titleFour}>胃肠</div>
            <div className={styles.content}>
                1. 胃底腺息肉是所有胃息肉中最为常见的一种良性病变，好发于50岁以上中老年人群。发现胃底腺息肉往往没有幽门螺杆菌感染，更提示不会发生胃癌。
                <br />
                2. 很多人把粥看作是养胃“良方”，但长期吃易消化的食物，胃的消化能力会退化。平时可多吃一些固体类食物（米饭、馒头、玉米、肉类、水果等）慢慢提升胃的运动功能，只要没有造成胃痛、胃胀等就说明在承受范围内。
                <br />
                3. 胃容量不会因为饥饿而变小。如果长期空腹，长时间可能导致低血糖，另外过多胃酸会损伤胃黏膜。
                <br />
                4. 一日三餐要定时规律，这样才可以形成固定消化液分泌高峰。
                <br />
                5. 胃癌发病高峰一般在50岁后，40岁以前多是遗传性因素。环境因素是长年累月、“水滴石穿”的影响。针对胃癌的预防，要少油盐、少腌制饮食。
                <br />
                6. 在医学上，根本没有“宿便”这个说法。直肠平时是空的，并不积存大便，只有少量堆积在结肠末端准备排出体外的粪便。
                <br />
                7. 80%左右的大肠癌是由息肉恶变而来，预防要从肠息肉开始。久坐得时间越长患肠息肉的风险越高；少吃高脂肪、高动物蛋白、低纤维食物，减少油煎、烟熏等烹饪方式。
            </div>
            <div className={styles.titleFour}>心脏</div>
            <div className={styles.content}>
                1. 吃饭八分饱，心脏会更好。摄入过多的食物，特别是高蛋白、高脂肪食物难以消化，使腹部膨胀不适，膈肌位置升高，从而限制了心脏的正常收缩和舒张，加重心脏负担。
                <br />
                2. 冠心病、心肌梗死的典型症状是胸痛，此外还有胃疼、嗓子疼、牙疼、后背疼、类似肩周炎症状等非典型症状。目前认为心绞痛是通过神经系统放射到其他部位引起症状。
                <br />
                3. 在四五十岁时，心脏和血管都会有明显的老化，弹性大不如前，特别是对有心血管病家族史的人来说，定期检查很有必要。
                <br />
                4. 心脏早搏虽是心脏的不规则地跳动，但并非皆由疾病造成。情绪紧张、激动、焦虑，吸烟，大量的饮酒、喝浓茶，甚至便秘皆可引起心脏早搏。
            </div>
            <div className={styles.titleFour}>肺脏</div>
            <div className={styles.content}>
                1. 有些年轻人爬层楼梯气喘吁吁，有些老人一天到晚神采奕奕，差别就在心肺耐力。心肺耐力的核心就在于人体对氧的摄取、转运和利用。提升心肺耐力的方法多推荐有氧运动。
                <br />
                2. 肺小结节95%都是良性的，但是也还是要警惕。一定要到专科门诊看看，注意复查。
                <br />
                3. 吸烟是导致肺癌高发的首要因素。吸烟造成的肺部问题或者其他慢性病，会在大量吸烟后的20、30年才显示出来。
                <br />
                4. 肺癌筛查选低剂量螺旋CT，是因为其辐射剂量约是普通CT的1/5。有时候部分病灶的进一步确诊还是需要高分辨CT，甚至是增强CT来确认。
            </div>
            <div className={styles.titleFour}>骨骼</div>
            <div className={styles.content}>
                1. 骨刺是身体在老化过程中，因受力不均匀，局部的骨头受刺激不均而导致。只要不发炎，骨刺其实是对身体的一种保护性装置，这时可以完全不用理睬它。
                <br />
                2. “硬骨头” 离不开运动。人运动时，骨头会因受力而微微变形，刺激分泌细胞因子，来调动骨质的重建单元，自然骨质就不易疏松。
                <br />
                3. 小个子，小骨架的人天生骨量少，罹患骨质疏松症的年龄会更早些，建议从30岁开始，多吃奶制品等富钙食品，多运动，增加骨量储备。
                <br />
                4. 建议40岁以上人群开始进行骨密度检测。专业骨密度检测（双能X线吸收仪）是公认诊断骨质疏松症的金标准。
                <br />
                5. 有些“骨头响”是正常的生理现象，是由于关节腔内的气体振动造成的。生理性弹响必须同时满足以下4个条件：只发生在关节受到突然的牵拉或屈折时；弹响前，关节必须有一定时间的休息期；响声清脆、单一、不重复；伴有疼痛或不适感， 弹响后常有轻松感；有时疼痛或不适可能并不明显。如果不符合上述4个条件中的任何一个，即为病理性弹响。
            </div>
        </div>
    )
} 