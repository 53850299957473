import styles from './articalFirst.module.css'
import img from '../../components/images/articalFrst.webp'
import React, { useEffect } from 'react'
import {Share} from "../../components/Share/share";
import { LeftOutline } from 'antd-mobile-icons'
import { useHistory } from 'react-router-dom'

export const ArticalFirst = () => {

    const history = useHistory()

    return (
        <div className={styles.articalFirst}>
            <Share title={'健康科普｜30个医学常识'} param={'&articalId=1'}/>
            <div className={styles.title}>
                <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
                健康科普｜30个医学常识
            </div>
            {/* <div style={{ marginBottom: '16px', color: '#999' }}>PRP离心机朗泰生物 2023-08-16 15:48 广东</div> */}
            <img style={{ width: '100%', height: '150px' }} src={img} alt="" />
            <div className={styles.titleSecond}>1. 正常心率：每分钟75次</div>
            <div className={styles.content}>健康成年人安静状态下，心率平均为每分钟75次。正常范围为每分钟60-100次。成人安静时心率超过100次/分钟，为心动过速；低于60次/分钟者，为心动过缓。心率可因年龄、性别及其他因素而变化，比如体温每升高1℃，心率可加快12-20次/分钟，女性心率比男性心率稍快，运动员的心率较慢。</div>
            <div className={styles.titleSecond}>2. 正常体温：36.3℃-37.2℃（口测法）</div>
            <div className={styles.content}>临床上通常用口腔温度、直肠温度和腋窝温度来代表体温。口测法（舌下含5分钟）正常值为36.3℃-37.2℃；腋测法（腋下夹紧5分钟）为36℃-37℃；肛测法（表头涂润滑剂，插入肛门5分钟）为36.5℃-37.7℃。在一昼夜中，人体体温呈周期性波动，一般清晨2-6时最低，下午13-18时最高，但波动幅度一般不超过1℃。只要体温不超过37.3℃，就算正常。</div>
            <div className={styles.titleSecond}>3. 血红蛋白（HbB）：成年男性（120-160克/升），成年女性（110-150克/升）</div>
            <div className={styles.content}>临床上以血红蛋白值作为判断贫血的依据。正常成人血红蛋白值90-110克/升属轻度贫血；60-90克/升属中度贫血；30-60克/升属重度贫血。</div>
            <div className={styles.titleSecond}>4. 白细胞计数（WBC）：4-10*（10的9次方）个/升</div>
            <div className={styles.content}>白细胞计数大于10*（10的9次方）个/升称白细胞增多，小于4*（10的9次方）个/升称白细胞减少。一般地说，急性细菌感染或炎症时，白细胞可升高；病毒感染时，白细胞会降低。感冒、发热可由病毒感染引起，也可由细菌感染引起，为明确病因，指导临床用药，医生通常会让你去查一个血常规。</div>
            <div className={styles.titleSecond}>5. 血小板计数（PLT）：100-300*（10的9次方）个/升</div>
            <div className={styles.content}>血小板有维护血管壁完整性的功能。当血小板数减少到50*（10的9次方）个/升以下时，特别是低至30*（10的9次方）个/升时，就有可能导致出血，皮肤上可出现瘀点瘀斑。血小板不低皮肤上也常出现“乌青块”者不必过分紧张，因为除了血小板因素外，血管壁因素，凝血因素，以及一些生理性因素都会导致“乌青块”的发生，可去血液科就诊，明确原因。</div>
            <div className={styles.titleSecond}>6.尿量：1000~2000毫升/24小时</div>
            <div className={styles.content}>24小时尿量&gt;2500毫升为多尿生理性多尿。见于饮水过多或应用利尿药后。病理性多尿见于糖尿病尿崩症肾小管疾病等。<br />24小时尿量{'<'}400毫升为少尿，多见于饮水过少脱水肾功能不全等。<br />24小时尿量{'<'}100毫升为无尿，多见于肾功能衰竭休克等严重疾病。<br />提醒：如果您感觉自己最近尿量明显增多，特别容易口渴，应去医院检查，排除自己是否有糖尿病或肾小管疾病，若发现自己最近饮水不少，但尿量偏少，身上还有点肿，更应及时检查，排除肾脏疾患。<br /></div>
            <div className={styles.titleSecond}>6.尿量：1000~2000毫升/24小时</div>
            <div className={styles.content}>24小时尿量&gt;2500毫升为多尿生理性多尿。见于饮水过多或应用利尿药后。病理性多尿见于糖尿病尿崩症肾小管疾病等。<br />24小时尿量{'<'}400毫升为少尿，多见于饮水过少脱水肾功能不全等。<br />24小时尿量{'<'}100毫升为无尿，多见于肾功能衰竭休克等严重疾病。<br />提醒：如果您感觉自己最近尿量明显增多，特别容易口渴，应去医院检查，排除自己是否有糖尿病或肾小管疾病，若发现自己最近饮水不少，但尿量偏少，身上还有点肿，更应及时检查，排除肾脏疾患。<br /></div>
            <div className={styles.titleSecond}>7.夜尿量：500毫升</div>
            <div className={styles.content}>夜尿指晚8时至次日晨8时的总尿量，一般为500毫升，排尿2~3次。若夜尿量超过白天尿量，且排尿次数明显增多，称为夜尿增多。生理性夜尿增多与睡前饮水过多有关；病理性夜尿增多常为肾脏浓缩功能受损的表现，是肾功能减退的早期信号
                提醒：夜尿增多是一个异常信号，大家应引起足够重视除肾功能减退以外，夜尿增多还可能是男性前列腺增生、老年女性子宫脱垂、泌尿系统感染、糖尿病、精神紧张等原因所致。</div>
            <div className={styles.titleSecond}>8.尿红细胞数（RBC）正常值：0~3个/高倍视野</div>
            <div className={styles.content}>尿红细胞{'>'}3个/高倍视野，称镜下血尿尿红细胞{'>'}10个/高倍视野，称肉眼血尿提醒：尿红细胞计数若超过3个，常提示肾脏有病变或泌尿系统有感染，应即刻就医；女性应避免在月经期查尿常规，以免经血混入尿液影响化验结果。</div>
            <div className={styles.titleSecond}>9.尿白细胞计数（WBC）正常值：{'<'}5个/高倍视野</div>
            <div className={styles.content}>尿白细胞计数若{'>'}5个/高倍视野，称镜下脓尿提醒：尿中若有大量白细胞，多为泌尿系感染，如肾盂肾炎肾结核膀胱炎或尿道感染如果尿中有白细胞，但少于5个/高倍视野，就算正常。</div>
            <div className={styles.titleSecond}>10.两大血型系统：ABO和Rh</div>
            <div className={styles.content}>血型系统将血液分为4型：A型 B型 AB型 和O型
                Rh血型系统将血型分为两型：Rh阳性型和Rh阴性型
                在白种人中，85%为Rh阳性血型，15%为Rh阴性血型
                在我国，99%的汉族人属Rh阳性血型，Rh阴性属稀有血型
                提醒：如果爸爸是A型血，妈妈是B型血，孩子应该是什么血型？正确答案是A型B型AB型或O型。千万不要因为夫妻二人都不是O型，却生出一个O型宝宝，而觉得奇怪哦！
                注意：双方若有一人为AB型，宝宝就不可能是O型
                双方若都是O型，宝宝只能是O型。</div>
            <div className={styles.titleSecond}>11.体重指数（BMI）=体重（千克）/身高（米）的平方：18.5~23.9属正常</div>
            <div className={styles.content}>体重指数{'<'}18.5为体重过低，体重指数24.0~27.9为超重，体重指数28.0为肥胖
                提醒：如果你的体重指数超过24,说明你已经偏胖，需要减肥了。
                记住：肥胖是百病之源，如果听之任之，糖尿病高血压冠心病都会找上你。</div>
            <div className={styles.titleSecond}>12.最佳减肥速度：每月减重1~2公斤</div>
            <div className={styles.content}>提醒：体重超标的人应在医生指导下逐步减轻体重，减肥过快过猛会导致体重反弹、厌食症、贫血、营养不良、月经不调、脱发、记忆力减退、骨质疏松等不良反应。</div>
            <div className={styles.titleSecond}>13.腰围：男性90厘米，女性80厘米</div>
            <div className={styles.content}>大于则为腹型肥胖腰围是判断腹部脂肪蓄积，即腹型肥胖（也称为苹果型肥胖）的指标腹型肥胖。目前被认为是冠心病，代谢综合征的重要危险因素。
                提醒：如果你的体重指数尚未达到肥胖程度，但腰围已超标，说明你属于腹型肥胖。腹型肥胖的人比全身都胖的人更危险，更容易受冠心病糖尿病的青睐，当心！</div>
            <div className={styles.titleSecond}>14.肥胖信号：一个月增重1.5公斤</div>
            <div className={styles.content}>提醒：在体重刚开始往上长的时候就及时发现，并采取减肥措施，往往能收到显着效果。出现下列情况，常提示有体重增加趋势：稍稍运动就喘不过气来，有疲倦无力感，动不动就汗流浃背，出现下背髋部及膝关节疼痛。</div>
            <div className={styles.titleSecond}>15.血压正常值：收缩压{'<'}140毫米汞柱，舒张压{'<'}90毫米汞柱</div>
            <div className={styles.content}>在安静时测量大于140毫米汞柱和（或）舒张压90毫米汞柱，可诊断为高血压。
                收缩压大于140毫米汞柱，但舒张压{'<'}90毫米汞柱，称为单纯收缩期高血压。
                提醒：高血压是心脑血管疾病的重要危险因素，高血压患者应在医生指导下应用降压药物，把血压控制在正常范围。有糖尿病肾病者，最好将血压控制在130/80毫米汞柱以下。</div>
            <div className={styles.titleSecond}>16.糖化血红蛋白正常值6.5%</div>
            <div className={styles.content}>糖化血红蛋白是红细胞内的血红蛋白与葡萄糖结合的产物，能反映采血前3个月的平均血糖水平，是目前反映血糖控制好坏最有效、最可靠的指标。
                糖尿病患者应将糖化血红蛋白7.0%作为治疗达标的标准之一，老年人可略放宽标准（7.0%~7.5%），中青年人应将糖化血红蛋白控制在6.5%或更低。
                提醒：糖化血红蛋白每下降1%,糖尿病相关并发症可减少20%! 因此，即使是无法达到上述标准的糖尿病患者，也应尽可能将糖化血红蛋白降至接近正常。</div>
            <div className={styles.titleSecond}>17.骨密度最高的时期：30~40岁</div>
            <div className={styles.content}>每个人一生中骨密度最高（骨峰值）的时期一般出现在30~40岁，受出生后营养发育和遗传等因素的影响，骨峰值有高有低。男性一般从40岁开始，女性一般从35岁开始，骨峰值开始下降。女性在绝经后5年内，男性在70岁以后，骨量丢失最快。
                提醒：骨峰值高的人，其骨内钙含量高，年老以后发生骨质疏松的程度较轻，时间较晚。因此，40岁之前的人应把握机会，保证每天足够的营养和钙的摄入，并积极参加体育锻炼，努力提高自己的骨峰值。</div>
            <div className={styles.titleSecond}>18.每人每日用油25克为宜</div>
            <div className={styles.content}>提醒：烹调时最好用植物油，因为植物油含对心脏有益的不饱和脂肪酸较多，由于油的热量比较高，因此用量不宜过多，否则热量过剩，也会转化为体内脂肪，让你变胖。</div>
            <div className={styles.titleSecond}>19.每天食盐量不宜超过6克</div>
            <div className={styles.content}>提醒：盐是引发高血压的重要危险因素，口味较重的人应特别注意每天的食盐量还应包括酱油腌菜咸蛋中的含盐量。</div>
            <div className={styles.titleSecond}>20.每天吃蔬菜400~500克</div>
            <div className={styles.content}>提醒：每天食用的蔬菜一半以上应为有色蔬菜，如绿叶蔬菜、红黄色蔬菜。蔬菜富含维生素、矿物质、纤维素，热量又很低，是真正的健康食品。</div>
            <div className={styles.titleSecond}>21.健康成人每日摄入钙600~800毫克</div>
            <div className={styles.content}>中国营养学会推荐的钙供给量：成人600~800毫克/天，孕妇1200毫克/天，乳母2000毫克/天。
                提醒：乳及乳制品含钙丰富，吸收率高。水产品中，虾皮、海带、豆制品含钙较多。为促进钙的吸收，应适当补充维生素D并多晒太阳。</div>
            <div className={styles.titleSecond}>22.每天饮水1500毫升以上</div>
            <div className={styles.content}>提醒：饮水量包括每天摄入的茶水、汤水、果等食物的总含水量。饮水方式很有讲究，口不渴也要饮水，不要一次大量饮水，应饮白开水或清茶，不要用含糖饮料代替水等。</div>
            <div className={styles.titleSecond}>23.中风（卒中）康复最佳开始时机：脑梗死后3天，脑出血后5~7天</div>
            <div className={styles.content}>提醒：到目前为止，国内外还没有一种药物对偏瘫具有决定性的康复作用，唯有康复训练才是改善卒中病人功能障碍的有效方法。对卒中偏瘫病人而言，康复训练的效果远胜于用药，康复训练进行得越早越科学越完善，康复的机会就越大。</div>
            <div className={styles.titleSecond}>24.丙氨酰转氨酶（GPT或ALT）正常值{'<'}40单位/升</div>
            <div className={styles.content}>提醒：谷丙转氨酶升高多提示存在肝脏损害，如急慢性病毒性肝炎、酒精性肝炎、药物性肝炎、肝硬化等，应及时去医院就诊。</div>
            <div className={styles.titleSecond}>25.甲胎蛋白（AFP）{'<'}25微克/升（也可参照化验单上的正常参考值）</div>
            <div className={styles.content}>甲胎蛋白是在胎儿早期由肝脏合成的一种糖蛋白，出生后，AFP的合成很快受到抑制。当肝细胞发生恶变时，血中AFP含量明显增高。
                提醒：AFP升高，特别是略高于正常值时，并不一定表示患了恶性肿瘤，应动态观察，切莫惊慌失措。</div>
            <div className={styles.titleSecond}>26.18~45岁近视度数{'<'}1200度：可做准分子激光近视眼手术</div>
            <div className={styles.content}>提醒：年龄在18岁以下；近视度数不稳定，近年仍有逐年加深趋势；近视度数{'>'}1200度；眼部有活动性病变（如炎症青光眼干眼症等）；角膜中央厚度{'<'}450微米；有其他严重眼病（如圆锥角膜等）；有自身免疫性疾病、瘢痕体质及严重糖尿病者等，都不能做准分子激光近视眼手术。</div>
            <div className={styles.titleSecond}>27.40岁以上，每年查1次B超</div>
            <div className={styles.content}>超声检查具有无创无辐射便捷及准确等优点，不仅可发现结石囊肿脂肪肝等常见疾病，还能早期发现某些肿瘤。
                提醒：受超声波物理性质所限，超声检查较适合实体器官，如肝脾肾等，一般不适合含气器官（比如胃肠）和骨骼系统。</div>
            <div className={styles.titleSecond}>28.40岁以上女性，每年查1次钼靶摄影</div>
            <div className={styles.content}>近年来，乳腺癌的发病率呈逐年上升趋势。乳腺钼靶摄影对乳腺疾病，特别是对早期乳腺癌具有较高的诊断价值，临床上将其作为除体检之外首选的影像学诊断方法。如有乳腺癌家族史等高危因素，应从35岁开始，每年接受一次乳腺钼靶摄影检查。40岁以后，根据医生意见适当增加检查次数。
                提醒：35岁以下的年轻女性乳腺组织致密，乳腺钼靶摄影不易发现病灶，建议将超声检查作为乳腺癌早期诊断的手段。</div>
            <div className={styles.titleSecond}>29.女性45岁以上，男性55岁以上：每年测1次骨密度</div>
            <div className={styles.content}>我国60岁以上人群骨质疏松症的患病率，女性为30%~35%,男性为10%~20%。骨质疏松症对人体健康的危害是多方面的，如引起腰酸背痛，身材变矮，驼背和骨折等。
                提醒：目前最好的骨密度检测设备是双能X线吸收仪，俗称骨密度仪。骨密度仪可以测定腰椎股骨近端或者全身骨骼的骨量，能精确地反映骨量丢失情况。大家应将每年的测定数据妥善保管，并与自己第一次测得的骨密度值（初始数据）作比较，以便掌握自己每年骨量丢失的情况。一旦骨密度值达到骨质疏松的标准，就需要在医生的指导下进行正规的药物治疗，最大限度地提高骨密度，预防骨折发生。</div>
            <div className={styles.titleSecond}>30.冠脉狭窄{'>'}70%,需做介入治疗</div>
            <div className={styles.content}>一般认为，冠状动脉狭窄{'<'}70%,可先进行药物治疗；冠状动脉狭窄{'>'}70%,则需做介入治疗。若有明显症状，狭窄{'>'}50%也应做介入治疗。此外，一些特殊情况，比如冠状动脉狭窄程度不够介入治疗标准，但影像学显示病变形态不稳定，易发生血栓者，也应及早行介入治疗。
提醒：两支以上冠状动脉狭窄{'>'}50%,或接受过冠脉成形治疗并在冠状动脉内安装支架的患者再次发生心绞痛，应接受搭桥手术。</div>
        </div>
    )
}