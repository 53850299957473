/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 17:27:39
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-09 14:01:30
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { send } from '../../app/appSlice'
import { AppThunkApi, RootState } from '../../app/store'
import {
  addPatientList,
  patientSubmitParam,
  updatePatientList
} from '../../services/addPatientPeople'

export const getAddPatientListSubmit = createAsyncThunk<
  void,
  patientSubmitParam,
  AppThunkApi
>('addPatientPeopleList/addPatientList', async (params, api) => {
  return send(addPatientList(params), api)
})

export const updatePatientListSubmit = createAsyncThunk<
  void,
  patientSubmitParam,
  AppThunkApi
>('addPatientPeopleList/addPatientList', async (params, api) => {
  return send(updatePatientList(params), api)
})
