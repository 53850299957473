/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 17:27:39
 * @LastEditors: sj
 * @LastEditTime: 2021-11-23 09:24:20
 */

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 17:27:39
 * @LastEditors: sj
 * @LastEditTime: 2021-11-23 09:24:20
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { send } from '../../app/appSlice'
import { AppThunkApi, RootState } from '../../app/store'
import {
  appointmentTime,
  appointmentTimeData,
  appointmentTimeDataParams,
  appointmentTimeParams,
} from '../../services/appointmentTime'

// 查询医生排版信息刻度
export const getAppointmentTimePage = createAsyncThunk<
  void,
  appointmentTimeParams,
  AppThunkApi
>('appointmentTime/AppointmentTimePage', async (params, api) => {
  return send(appointmentTime(params), api)
})

// 查询预约医生排班刻度
export const getAppointmentTimeDataPage = createAsyncThunk<
  void,
  appointmentTimeDataParams,
  AppThunkApi
>('appointmentTime/AppointmentTimeData', async (params, api) => {
  return send(appointmentTimeData(params), api)
})
