/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-02-07 10:08:25
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 16:54:15
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-20 17:56:26
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-02 16:49:41
 */
import dayjs from 'dayjs'
import {
  Calendar,
  DotLoading,
  Card,
  InfiniteScroll,
  JumboTabs,
  List,
  Popup,
  Toast,
} from 'antd-mobile'
import PullToRefresh, {
  PullStatus,
} from 'antd-mobile/es/components/pull-to-refresh'
import moment, { max } from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch } from '../../app/store'
import boyHeadImg from '../../components/images/headImgBoy.png'
import girlHeadImg from '../../components/images/headImgGirl.png'
import styles from './onlineAppointment.module.css'
import rightIconArrow from '../../components/images/rightIconArrow.png'
import deleteCross from '../../components/images/deleteCross.png'
import {
  DateTimeFormat,
  DateTimeFormats,
  DateTimeFormatSimple,
} from '../../models/datetime'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getOnlineAppointmentList,
  getOnlineAppointmentTimeList,
} from './onlineAppointmentSlice'
import {selectAppId, selectTenantKey} from '../../app/appSlice'
import {
  getAppointmentTimeColor,
  getAppointmentTimeName,
  getWeekDayName,
} from '../../models/appointmentTime'

export interface onlineAppointmentTimeCard {
  tab?: any
}

export const OnlineAppointmentTimeCard: React.FC<onlineAppointmentTimeCard> = ({
  tab,
}): ReactElement => {
  const history: any = useHistory()

  const today = dayjs()

  const appid = useSelector(selectAppId)

  const tenantKey = useSelector(selectTenantKey)

  const dispatch = useDispatch<AppDispatch>()

  const [visible, setVisible] = useState(false)

  const [dataValues, setDataValues] = useState<any>()

  const [timeData, setTimeData] = useState<any>()

  const [isLoading, setIsLoading] = useState(false)

  const [changeTime, setChangeTime] = useState<any>(
    moment().format(DateTimeFormats)
  ) //点击上边tab选中那个的时间，用于筛选下边的时间段，默认今天

  const [data, setData] = useState<any>()

  const [defaultActiveTime, setDefaultActiveTime] = useState<any>()

  //从时间选择进来跟外边时间保持一致
  const defaultTime =
    history.location?.state?.changeTime || localStorage.getItem('defaultTime')

  // 弹框默认选中今天
  const defaultToday = new Date()

  const defaultSingle: any = moment(defaultToday).format(DateTimeFormats)

  // 最大可选时间范围
  const max = new Date(
    moment().add(30, 'days').endOf('day').format(DateTimeFormatSimple)
  )

  useEffect(() => {
    if (defaultTime) {
      localStorage.setItem('defaultTime', defaultTime ? defaultTime : '')
      setDefaultActiveTime(defaultTime)
    }
  }, [defaultTime])

  const dayName = [
    '今天',
    '明天',
    '周三',
    '周四',
    '周五',
    '周六',
    '周日',
    '周一',
    '周二',
    '周三',
    '周四',
    '周五',
    '周六',
    '周日',
    '周一',
    '周二',
    '周三',
    '周四',
    '周五',
    '周六',
    '周日',
    '周一',
    '周二',
    '周三',
    '周四',
    '周五',
    '周六',
    '周日',
    '周一',
    '周二',
    '周三',
  ]

  useEffect(() => {
    const startTime = moment().startOf('day').format(DateTimeFormats)
    const endTime = moment()
      .add(30, 'days')
      .startOf('days')
      .format(DateTimeFormats)
    if (tab == 1) {
      // 时间选择
      dispatch(
        getOnlineAppointmentTimeList({
          appid: appid,
          tenantKey: tenantKey,
          starDate: startTime,
          endDate: endTime,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setTimeData(
            res?.map((res: any, index: any) => {
              return {
                // name: dayName[index],
                name:
                  moment().format(DateTimeFormatSimple) ==
                  moment(res.date).format(DateTimeFormatSimple)
                    ? '今天'
                    : moment().add(1, 'days').format(DateTimeFormatSimple) ==
                      moment(res.date).format(DateTimeFormatSimple)
                    ? '明天'
                    : getWeekDayName(new Date(res.date).getDay()?.toString()),
                date: res.date,
                status: res?.status,
              }
            })
          )
        })
    }
  }, [tab])

  console.log(timeData, 'timeData')

  useEffect(() => {
    if (tab == 1)
      // 号源信息
      Toast.show({
        content: '加载中...',
        icon: 'loading',
        duration: 5000,
        maskClickable: false
      })
      setIsLoading(true)
      dispatch(
        getOnlineAppointmentList({
          tenantKey: tenantKey,
          date: changeTime ? changeTime : moment().format(DateTimeFormats),
        })
      )
        .then(unwrapResult)
        .then((v) => {
          setData(v)
          setIsLoading(false)
          Toast.clear()
        })
  }, [changeTime, tab])

  console.log(changeTime, 'allTimeData')

  useEffect(() => {
    if (changeTime) {
      setDefaultActiveTime(moment(changeTime).format(DateTimeFormats))
    } else {
      moment().format(DateTimeFormats)
    }
  }, [changeTime])

  return (
    <div className={styles.timeCardContent}>
      <div className={styles.timeCardContentTabsMain}>
        <JumboTabs
          activeKey={defaultActiveTime}
          className={styles.timeCardContentTabs}
          defaultActiveKey={changeTime}
          onChange={(v) => {
            setChangeTime(v)
          }}
        >
          {timeData?.map((v: any) => {
            return (
              <JumboTabs.Tab
                title=''
                description={
                  <div className={styles.dataMain}>
                    <div className={styles.data}>{v?.name}</div>
                    <div className={styles.dataTime}>
                      {moment(v?.date).format('MM/DD')}
                    </div>
                    <div
                      className={styles.dataState}
                      style={{
                        color: getAppointmentTimeColor(Number(v?.status)),
                      }}
                    >
                      {getAppointmentTimeName(Number(v?.status))}
                    </div>
                  </div>
                }
                key={v?.date}
              />
            )
          })}
        </JumboTabs>
        <div
          className={styles.timeCardContentRightIcon}
          onClick={() => {
            setVisible(true)
          }}
        >
          <img
            src={rightIconArrow}
            alt=''
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '4px',
              marginTop: '2px',
            }}
          />
        </div>
      </div>
      <div className={styles.timeCardContentTitle}>号源</div>
      {
        isLoading ? (
          <></>
        ) : (
            data?.length ? (
              data?.map((v: any) => {
                return (
                  <Card className={styles.timeCardContentCard} onClick={() => {
                    if (v?.status == 0) {
                      history.push({
                        pathname: '/appointmentTime',
                        state: {
                          doctorId: v?.doctorId,
                          // changeTime: changeTime,
                          tab: tab || '1',
                        },
                      })
                      localStorage.setItem('defaultTime', changeTime)
                    }
                  }}>
                    <img
                      src={v?.sex == 2 ? girlHeadImg : boyHeadImg}
                      alt=''
                      className={styles.timeCardContentImg}
                    ></img>
                    <div className={styles.timeCardContentMain}>
                      <div className={styles.timeCardContentName}>
                        {v?.doctorName}
                      </div>
                      <div className={styles.timeCardContentType}>
                        {v?.appointmentDepartmentName}
                      </div>
                    </div>
                    {v?.status == 0 ? (
                      <div
                        className={styles.timeCardContentButton}
                      >
                        预约
                      </div>
                    ) : v?.status == 1 ? (
                      <div className={styles.noTimeCardContentButton}>已满</div>
                    ) : (
                      '-'
                    )}
                  </Card>
                )
              })
            ) : (
              <div className={styles.noTimeListName}>暂无号源信息</div>
            )
        )
      }
      <Popup
        visible={visible}
        position='bottom'
        bodyStyle={{ height: '80vh', borderRadius: '5px 5px 0 0' }}
      >
        <div className={styles.dataTitle}>日期选择</div>
        <img
          className={styles.delectIcon}
          src={deleteCross}
          alt=''
          onClick={() => {
            setVisible(false)
          }}
        ></img>
        <div style={{ padding: '10px' }} className={styles.calenderContent}>
          <Calendar
            allowClear={false}
            defaultValue={defaultSingle}
            selectionMode='single'
            min={new Date()}
            max={max}
            renderLabel={(date: any) => {
              let thisDate = moment(date).format('YYYY-MM-DD')
              let status = timeData?.filter(
                (item: any) => item && item?.date && item?.date == thisDate
              )?.[0]?.status

              if ((dataValues || moment().format('YYYY-MM-DD')) == thisDate) {
                return (
                  <span className={styles.selectColor}>
                    {getAppointmentTimeName(status) ||
                      getAppointmentTimeName(2)}
                  </span>
                )
              } else {
                return (
                  <span
                    className={
                      status == 0
                        ? styles.statusZero
                        : status == 1
                        ? styles.statusOne
                        : styles.statusTwo
                    }
                  >
                    {getAppointmentTimeName(status) ||
                      getAppointmentTimeName(2)}
                  </span>
                )
              }
            }}
            onChange={(value) => {
              setDataValues(moment(value).format(DateTimeFormats))
              console.log(moment(value).format(DateTimeFormats), 'value')
            }}
          />
          <div className={styles.bottomButtons}>
            <div
              className={styles.bottomButtonTexts}
              onClick={() => {
                setVisible(false)
                setChangeTime(dataValues)
              }}
            >
              确认
            </div>
          </div>
        </div>
      </Popup>
    </div>
  )
}
