/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 17:27:39
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-09 14:43:14
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { send } from '../../app/appSlice'
import { AppThunkApi, RootState } from '../../app/store'
import {
  onlineAppointmentDoctorCardList,
  onlineAppointmentDoctorParams,
  onlineAppointmentList,
  onlineAppointmentParams,
  onlineAppointmentTimeList,
  onlineAppointmentTimeParams,
} from '../../services/onlineAppointment'

// 号源信息
export const getOnlineAppointmentList = createAsyncThunk<
  void,
  onlineAppointmentParams,
  AppThunkApi
>('onlineAppointment/OnlineAppointmentList', async (params, api) => {
  return send(onlineAppointmentList(params), api)
})

// 按医生列表
export const getOnlineAppointmentDoctorCardList = createAsyncThunk<
  void,
  onlineAppointmentDoctorParams,
  AppThunkApi
>('onlineAppointment/onlineAppointmentDoctorCard', async (params, api) => {
  return send(onlineAppointmentDoctorCardList(params), api)
})


// 按时间查询时间列表
export const getOnlineAppointmentTimeList = createAsyncThunk<
  void,
  onlineAppointmentTimeParams,
  AppThunkApi
>('onlineAppointment/getOnlineAppointmentTimeList', async (params, api) => {
  return send(onlineAppointmentTimeList(params), api)
})
