/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 17:27:39
 * @LastEditors: sj
 * @LastEditTime: 2021-11-23 09:24:20
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { send } from '../../app/appSlice'
import { AppThunkApi, RootState } from '../../app/store'
import {
  myVisitorsPage,
  myVisitorsPageParams,
  myVisitorsRemove,
  myVisitorsRemoveParams,
} from '../../services/myVisitorsList'
import { patientRemove, patientRemoveParams } from '../../services/visitorsList'

// 就诊人列表查询
export const getMyVisitorsPage = createAsyncThunk<
  void,
  myVisitorsPageParams,
  AppThunkApi
>('myVisitors/myVisitorsPage', async (params, api) => {
  return send(myVisitorsPage(params), api)
})

// 删除
export const getMyVisitorsRemove = createAsyncThunk<
  void,
  myVisitorsRemoveParams,
  AppThunkApi
>('patientList/myVisitorsRemove', async (params, api) => {
  return send(myVisitorsRemove(params), api)
})
