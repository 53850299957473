import { API_PREFIX, Request } from './request'

export interface patientPageParams {
  current?: any
  size: any
  tenantKey?: any
  openid?: any
}

export function patientPage(params: patientPageParams): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechatpatient/page`,
    method: 'GET',
    params,
  }
}

export interface patientRemoveParams {
  ids?: any
}

export function patientRemove(params: patientRemoveParams): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechatpatient/remove`,
    method: 'POST',
    params,
  }
}

