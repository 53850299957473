/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-02-09 16:36:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 14:08:59
 */
import { API_PREFIX, Request } from './request'

export interface CouponsParams {
    patientId?: string
    tenantKey?: string
    useStatus?: string
}

export function getCoupons(params: CouponsParams): Request {
    return {
        url: `${API_PREFIX}/blade-thirdparty/wechat/tenant/getTenantPatientCoupons`,
        method: 'GET',
        params,
    }
}

;