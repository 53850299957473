/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-19 10:12:58
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-07 09:41:32
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ServiceError } from '../services/errors'
import * as R from '../services/request'
import { Request } from '../services/request'
import { AppDispatch, RootState } from './store'

export const CREDENTIALS_KEY = 'triage_supervisor_credentials'

interface Credentials {
  // id: string
  // name: string
  // tenantName: string
  // tenantId: string
  // token: string
  // shortName?: string
  // accountId?: string
  appId?: any
  openId?: any
  tenantKey: string
  accessToken: string
}

interface AppError {
  code: number
  message: string
}

interface AppState {
  credentials?: Credentials
  appError?: AppError
  header?: any
  // tenantId?: number
}

const initialState = {
  credentials: (function (): Credentials | undefined {
    const json = localStorage.getItem(CREDENTIALS_KEY)
    if (json) {
      try {
        return JSON.parse(json)
      } catch {
        // do nothing.
      }
    }
    // return undefined
    return {
      appId: 'wx72246c6fb90ec406',
      tenantKey: '6A1D652B2D57A404',
      accessToken: 'dd',
      openId: 'oXbc569pLqnoLf6KuSTvs3JU237U',
    }
  })(),
  header: {},
  tenantId: 0,
} as AppState

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.credentials = action.payload
      if (action.payload) {
        localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(action.payload))
      } else {
        localStorage.removeItem(CREDENTIALS_KEY)
      }
    },
    setAppError: (state, action: PayloadAction<AppError | undefined>) => {
      state.appError = action.payload
    },
    setAppHeader: (state, action) => {
      state.header = action.payload
    },
    // setTenantId: (state, action) => {
    //   state.tenantId = action.payload
    // },
    // Logout(state, action) {
    //   localStorage.removeItem(CREDENTIALS_KEY)
    //   state.credentials = action.payload
    // },
    // setAppId: (state, action) => {
    //   state.appId = action.payload
    // },
    // setOpenId: (state, action) => {
    //   state.openId = action.payload
    // },
  },
})

function setCommonHeaders(
  request: Request,
  getState: () => RootState
): Request {
  const creds = getState().app.credentials
  const headers = request.headers || {}
  return {
    ...request,
    headers: creds ? { ...headers, 'HIS-Auth': creds.tenantKey } : headers,
    // headers: {
    //   ...headers,
    //   'HIS-Auth':
    //     'earer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJwZXJtaXNzaW9uX3R5cGUiOiIxIiwidXNlcl9pZCI6IjQ4IiwidXNlcl9uYW1lIjoi566h55CG5ZGYIiwiY2xpZW50X2lkIjoiY3ljbGluIiwiZXhwIjoxNjY4MDIwMzk5LCJuYmYiOjE2Njc5NTYzODl9.wV2ME6uBo7V52Awxa2GD3KXwE_F0AqjQrlC67e821Kvd9OCOYvKZTzGLXeydUuiMC9Gl_83apuTHbx8jmKoawg',
    // },
  }
}

export async function send(
  request: Request,
  {
    getState,
    dispatch,
  }: {
    getState: () => RootState
    dispatch: AppDispatch
  },
  ignoreError = false
): Promise<any> {

  const result = R.send(setCommonHeaders(request, getState)).catch((e) => {
    const error =
      e instanceof ServiceError
        ? {
            code: e.code,
            message: e.message,
          }
        : { code: 500, message: e.message }
    if (error.code === 401) {
      dispatch(setCredentials(undefined))
    } else if (!ignoreError) {
      dispatch(setAppError(error))
    }
    throw e
  })
  return result;

}

export const { setCredentials, setAppError } = appSlice.actions

export const selectLoginState = (state: RootState): boolean =>
  !!state.app?.credentials

// export const selectLoginStatus = (state: RootState): RootState => state.app.loginStatus;
export const selectAppError = (state: RootState): AppError | undefined =>
  state.app?.appError

export const selectAppMsg = (state: RootState): any => state.app.credentials

// export const selectUserName = (state: any): RootState =>
//   state.app.credentials?.name

// export const selectTenantName = (state: RootState): any =>
//   state.app.credentials?.tenantName

// export const selectUserId = (state: RootState): any => state.app.credentials?.id

// export const selectAccountId = (state: RootState): any =>
//   state.app.credentials?.accountId

// export const selectToken = (state: RootState): any =>
//   state.app.credentials?.token

// export const selectShortName = (state: RootState): any =>
//   state.app.credentials?.shortName

export const selectAppId = (state: RootState): any =>
  state.app.credentials?.appId

export const selectTenantKey = (state: RootState): any =>
  state.app.credentials?.tenantKey

export const selectAccessToken = (state: RootState): any =>
    state.app.credentials?.accessToken

export const selectOpenId = (state: RootState): any =>
  state.app?.credentials?.openId

export const selectHeader = (state: RootState): any => state.app.header

// export const selectTenantId = (state: RootState): any => state.app.tenantId

export default appSlice.reducer
