export function getAppointmentTimeName(status: any) {
  switch (status) {
    case 0:
      return '有号'
    case 1:
      return '已满'
    case 2:
      return '未放号'
    default:
      break
  }
}

export function getAppointmentTimeColor(status: any) {
  switch (status) {
    case 0:
      return '#29BE78'
    case 1:
      return '#FFB145'
    case 2:
      return '#ccc'
    default:
      break
  }
}

export function getWeekDayName(status: any) {
  switch (status) {
    case '0':
      return '周日'
    case '1':
      return '周一'
    case '2':
      return '周二'
    case '3':
      return '周三'
    case '4':
      return '周四'
    case '5':
      return '周五'
    case '6':
      return '周六'
    default:
      break
  }
}
