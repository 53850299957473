/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 17:27:39
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-10 10:40:36
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { send } from '../../app/appSlice'
import { AppThunkApi, RootState } from '../../app/store'
import {
  appointmentTreatmentPeoplesubmit,
  appointmentTreatmentPeopleSubmitParam,
  defaultPeopleList,
  defaultPeopleListParams,
} from '../../services/appointmentTreatmentPeople'

export const getAppointmentTreatmentPeopleSubmit = createAsyncThunk<
  void,
  appointmentTreatmentPeopleSubmitParam,
  AppThunkApi
>(
  'AppointmentTreatmentPeople/AppointmentTreatmentPeopleSubmit',
  async (params, api) => {
    return send(appointmentTreatmentPeoplesubmit(params), api)
  }
)

export const getDefaultPeopleList = createAsyncThunk<
  void,
  defaultPeopleListParams,
  AppThunkApi
>('AppointmentTreatmentPeople/defaultPeopleList', async (params, api) => {
  return send(defaultPeopleList(params), api)
})
