/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-02-09 13:59:14
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-09 15:47:19
 */
import { API_PREFIX, Request } from './request'
import { getWechatJsSdkSignature } from '../features/home/HomeSlice'

export interface appointmentTreatmentPeopleSubmitParam {
  appointmentComment?: any
  appointmentStartTime?: any
  appointmentEndTime?: any
  tenantKey?: any
  openid?: any
  sex?: any
  phone?: any
  ageMonth?: any
  ageYear?: any
}

export function appointmentTreatmentPeoplesubmit(
  body: appointmentTreatmentPeopleSubmitParam
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/appointmentRecordSave4Wechat`,
    method: 'POST',
    body,
  }
}

// 获取默认就诊人
export interface defaultPeopleListParams {
  tenantKey?: any
  openid?: any
}

export function defaultPeopleList(params: defaultPeopleListParams): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechatpatient/getLastPatient`,
    method: 'GET',
    params,
  }
}


