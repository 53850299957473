export function getSexName(sex: number) {
  switch (sex) {
    case 0:
      return '未知'
    case 1:
      return '男'
    case 2:
      return '女'
    case 9:
      return '未声明'
    default:
      break
  }
  return '-'
}
