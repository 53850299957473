import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { send } from '../../app/appSlice'
import { AppThunkApi, RootState } from '../../app/store'
import {
    getCoupons
} from '../../services/coupons'

// 首页数据
export const _getCoupons = createAsyncThunk<void, { patientId: string, tenantKey: string, useStatus: string}, AppThunkApi>(
    'home/page',
    async (params, api) => {
        return send(getCoupons(params), api)
    }
)