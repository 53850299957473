/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 17:27:39
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 14:10:21
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { send } from '../../app/appSlice'
import { AppThunkApi, RootState } from '../../app/store'
import { doctorDetail, doctorDetailFlag, doctorDetailFlagParams } from '../../services/doctorDetail'

// 医生详情
export const getDoctorDetail = createAsyncThunk<void, any, AppThunkApi>(
  'doctorDetail/getDoctorDetail',
  async (params, api) => {
    return send(doctorDetail(params), api)
  }
)

// 在线预约
export const getDoctorDetailFlag = createAsyncThunk<
  void,
  doctorDetailFlagParams,
  AppThunkApi
>('doctorDetail/doctorDetailFlag', async (params, api) => {
  return send(doctorDetailFlag(params), api)
})

