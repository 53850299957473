/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-02-09 16:36:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 14:08:59
 */
import { API_PREFIX, Request } from './request'

export function doctorDetail(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/detail4Wechat`,
    method: 'POST',
    params: id,
  }
}


export interface doctorDetailFlagParams {
  tenantKey?: string
  doctorId?: any
  endDate?: any
  starDate?: any
}

export function doctorDetailFlag(params: doctorDetailFlagParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentDoctorScaleByDocterId4Wechat`,
    method: 'GET',
    params,
  }
}

;