/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:33:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 18:57:37
 */
import { DefaultLayout } from '../layout/DefaultLayout'
import './App.css'
import dd from 'gdt-jsapi'
import { unwrapResult } from '@reduxjs/toolkit'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoginState, setCredentials } from './appSlice'
import { AppDispatch } from './store'
import { Dialog, Toast } from 'antd-mobile'
import { getOpenIdParams } from '../services/home'
import { getOpenIdByCode } from '../features/home/HomeSlice'
import styles from './app.module.css'
import wx from 'weixin-js-sdk' // 引入微信js-sdk

export const App = () => {
  // const queryParams = new URLSearchParams('?appid=wx72246c6fb90ec406&redirect_uri=https%3A%2F%2Fpatienth5.hydhis.cn%2F&response_type=code&scope=snsapi_base&state=r7Km25fG82jD&connect_redirect=1#wechat_redirect')
  const queryParams = new URLSearchParams(window.location.search)
  const code = queryParams.get('code') //一次性参数，使用过后即无效
  const state = queryParams.get('state') //参数与openid一起需要保留到当前会话中，后续其他后端接口调用需要
  const share = queryParams.get('share') //分享标志
  const subAddress = queryParams.get('subAddress') //分享标志

  const dispatch = useDispatch<AppDispatch>()

  const [visible, setVisible] = useState(false)

  const isLogin = useSelector(selectLoginState)

  // useEffect(() => {
  //   if (appid)
  //     dispatch(
  //       setCredentials({
  //         appId: appid || 'wx72246c6fb90ec406',
  //       })
  //     )
  // }, [appid])

  // useEffect(() => {
  //   dispatch(
  //     setCredentials({
  //       openId: 'oXbc56wmsCE8I9g3rEm1N3q1KmPM',
  //       appId: 'wx72246c6fb90ec406',
  //     })
  //   )
  // }, [])

  useEffect(() => {
    if (share && share === 'true') {

      const doctorId = queryParams.get('doctorId')
      if (doctorId) {
          localStorage.setItem('doctorId',doctorId)
      }
      const tab = queryParams.get('tab')
      if (tab) {
          localStorage.setItem('tab',tab)
      }
      const defaultTime = queryParams.get('defaultTime')
      if (defaultTime) {
          localStorage.setItem('defaultTime',defaultTime)
      }
      const articalId = queryParams.get('articalId')
      if (articalId) {
          localStorage.setItem('articalId',articalId)
      }

      let redirectUrl = window.location.href.split('?')[0]
      if (subAddress && subAddress !== '') {
          redirectUrl += subAddress
      }
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx72246c6fb90ec406&redirect_uri='+ redirectUrl + '&response_type=code&scope=snsapi_base&state=' + state + '#wechat_redirect';
      return;
    }
    //1.调用后端接口，通过code+appid获取openid
    if (code && state) {
      // if(isLogin){return}
      localStorage.removeItem('doctorId')
      localStorage.removeItem('tab')
      localStorage.removeItem('defaultTime')
      const param: getOpenIdParams = {
        code: code,
        appid: 'wx72246c6fb90ec406',
      }
      dispatch(getOpenIdByCode(param))
        .then(unwrapResult)
        .then((res: any) => {
          dispatch(
            setCredentials({
              openId: res.openid,
              accessToken: res.access_token,
              appId: 'wx72246c6fb90ec406',
              tenantKey: state
            })
          )
        })
        .catch((v) => { })
    } else {
      //TODO 没有code和appid时，代表不是从微信页面进入，需要提示用户 “请从微信菜单进入” 然后return
      setVisible(true)
      return
    }
  }, [])

  return (
    <div className='App'>
      <DefaultLayout />
      <div className={styles.dialogMain}>
        <Dialog
          visible={visible}
          content='请从微信菜单进入'
          actions={[
            {
              key: 'delete',
              text: '确认',
              style: { color: '#408CFF' },
              onClick: () => {
                setVisible(false)
                setTimeout(() => {
                  wx.closeWindow()
                }, 1000)
              },
            },
          ]}
        />
      </div>
    </div>
  )
}

export default App

