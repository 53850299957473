
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './patientDetail.module.css'
import { useLocation } from 'react-router-dom'
import {
    getDefaultPatient,
    getDefaultPatientCode,
    updateDeafaultPatient
} from '../home//HomeSlice'
import React, { useEffect, useState } from 'react'
import { selectOpenId, selectTenantKey } from '../../app/appSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import boyHeadImg from '../../components/images/patientBoy.svg'
import girlHeadImg from '../../components/images/patientGirl.svg'
import { Share } from "../../components/Share/share";
import { LeftOutline } from 'antd-mobile-icons'

export const PatientDetail = () => {
    const dispatch = useDispatch<AppDispatch>()

    const history = useHistory()

    const state: any = history?.location?.state

    const id = state.id

    const tenantKey = useSelector(selectTenantKey)

    const openid = useSelector(selectOpenId)

    const [defaultPeople, setDefaultPeople] = useState<any>()

    const [qrCode, setQrCode] = useState<any>()

    useEffect(() => {

        if (id) {
            dispatch(updateDeafaultPatient({ tenantKey: tenantKey, openid: openid, id: id }))
                .then(unwrapResult)
                .then((res: any) => {
                    setDefaultPeople(res)
                    dispatch(getDefaultPatientCode({ content: res?.patientId }))
                        .then(unwrapResult)
                        .then((v) => {
                            setQrCode('data:image/png;base64,' + v)
                        })
                })
        }
    }, [tenantKey, openid, id])

    return (
        <div className={styles.container}>
            <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
            <Share title={'首页'} param={''} />
            <div className={styles.bg}>
                <div className={styles.headIcon}>
                    <img src={defaultPeople?.sex === 1 ? boyHeadImg : girlHeadImg} style={{ width: '100%', height: '100%' }} alt="" />
                </div>
                <div className={styles.patientName}>{defaultPeople?.name}</div>
                <div className={styles.patientCard}>（就诊卡号：{defaultPeople?.patientId}）</div>
                <div className={styles.codeCard}>
                    <img src={qrCode} style={{ width: '100%', height: '100%' }} alt="" />
                    <div className={styles.threeIcon} style={{ top: '-8px', left: '-8px' }}></div>
                    <div className={styles.threeIcon} style={{ top: '-8px', right: '-8px', transform: 'rotate(90deg)' }}></div>
                    <div className={styles.threeIcon} style={{ bottom: '-8px', left: '-8px', transform: 'rotate(-90deg)' }}></div>
                    <div className={styles.threeIcon} style={{ bottom: '-8px', right: '-8px', transform: 'rotate(-180deg)' }}></div>
                </div>
                <div className={styles.info}>
                    <span>性别</span>
                    <span>{defaultPeople?.sex === 1 ? '男' : defaultPeople?.sex === 2 ? '女' : '未知'}</span>
                </div>
                {/* <div className={styles.info} style={{ margin: '19px 0' }}>
                    <span>证件号码</span>
                    <span>330123198006163218</span>
                </div> */}
                <div className={styles.info}>
                    <span>联系电话</span>
                    <span>{defaultPeople?.phone}</span>
                </div>
            </div>
        </div>
    )
}