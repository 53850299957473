/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-02-09 15:20:24
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-10 10:07:52
 */
import { API_PREFIX, Request } from './request'
import { getWechatJsSdkSignature } from '../features/home/HomeSlice'

export interface patientSubmitParam {
  name?: any
  birthday?: any
  id?: any
  openid?: any
  sex?: any
  phone?: any
  ageMonth?: any
  ageYear?: any
  tenantAppId?: any
  tenantKey?: any
}

export function addPatientList(body: patientSubmitParam): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechatpatient/save`,
    method: 'POST',
    body,
  }
}

export function updatePatientList(body: patientSubmitParam): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechatpatient/update`,
    method: 'POST',
    body,
  }
}
