/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:33:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 18:54:56
 */

import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './onlineAppointment.module.css'
import { OnlineAppointmentDoctorCard } from './onlineAppointmentDoctorCard'
import { OnlineAppointmentTimeCard } from './onlineAppointmentTimeCard'
import { getWechatJsSdkSignature } from "../home/HomeSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import wx from "weixin-js-sdk";
import { Share } from "../../components/Share/share";
import { selectAppId, selectTenantKey } from "../../app/appSlice";
import { LeftOutline } from 'antd-mobile-icons'

export const OnlineAppointment = () => {
  const dispatch = useDispatch<AppDispatch>()

  const history = useHistory()

  const [tab, setTab] = useState<any>('0')


  useEffect(() => {
    localStorage.removeItem('doctorId')
    localStorage.removeItem('defaultTime')
    localStorage.removeItem('appointmentTimeDefaultTime')
    localStorage.removeItem('detailData')
  }, [])


  return (
    <div className={styles.onlineAppointmentContent}>
      <span className={styles.backIcon} onClick={() => history.replace({pathname: '/'})}><LeftOutline fontSize={16} /></span>
      <Share title={'在线预约'} param={''} />
      <Tabs onChange={(v) => setTab(v)}>
        <Tabs.Tab title='按医生' key='0'>
          <OnlineAppointmentDoctorCard tab={tab}></OnlineAppointmentDoctorCard>
        </Tabs.Tab>
        <Tabs.Tab title='按时间' key='1'>
          <OnlineAppointmentTimeCard tab={tab}></OnlineAppointmentTimeCard>
        </Tabs.Tab>
      </Tabs>
      {/* <div className={styles.onlineAppointmentBottom}>
        <div
          className={styles.onlineAppointmentBottomTextOne}
          onClick={() => {
            history.push({
              pathname: '/myVisitorsList',
            })
          }}
        >
          我的预约
        </div>
        <div
          className={styles.onlineAppointmentBottomTextTwo}
          onClick={() => {
            history.push({
              pathname: '/visitorsList',
            })
          }}
        >
          就诊人管理
        </div>
      </div> */}
    </div>
  )
}
