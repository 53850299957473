import { useDispatch } from "react-redux"
import { AppDispatch } from "../../app/store"
import { useHistory } from "react-router-dom"
import { Card } from "antd-mobile"
import styles from './setting.module.css'


export const Setting = () => {
    const dispatch = useDispatch<AppDispatch>()

    const history = useHistory()

    const detailData: any = history.location.state

    return (
        <div className={styles.container}>
            <Card>
                <div className={styles.info}>
                    <span>昵称</span>
                    <span>{detailData?.name}</span>
                </div>
                <div className={styles.info} style={{border: 'none'}}>
                    <span>手机号</span>
                    <span>{detailData?.phone}</span>
                </div>
                
            </Card>
            <div className={styles.centerImg}>切换账号</div>
        </div>
    )
}