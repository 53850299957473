/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-20 17:56:26
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 18:07:29
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Card,
  Dialog,
  InfiniteScroll,
  List,
  PullToRefresh,
  SwipeAction,
  SwipeActionRef,
  Toast,
} from 'antd-mobile'
import dd from 'gdt-jsapi'
import moment from 'moment'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch } from '../../app/store'
import noBg from '../../components/images/noBg.svg'
import { DateTimeFormatSimple } from '../../models/datetime'
import { getSexName } from '../../models/gender'
import { sleep } from '../../utils/system'
import { getMyVisitorsRemove } from './myVisitorsListSlice'
import styles from './myVisitorsList.module.css'
import { selectTenantKey } from '../../app/appSlice'
import {
  getAppointmentStateColor,
  getAppointmentStateName,
} from '../../models/myVisitorsList'

export interface myVisitorsProps {
  tab?: any
  data: any
  length: any
  refresh: () => void
  getMore: (hasMore: any) => void
}

export const MyVisitorsListCard: React.FC<myVisitorsProps> = ({
  tab,
  data,
  length,
  getMore,
  refresh,
}): ReactElement => {
  const history = useHistory()

  const ref = useRef<SwipeActionRef>(null)

  const dispatch = useDispatch<AppDispatch>()

  const [visible, setVisible] = useState(false)

  const [id, setId] = useState('')

  const [hasMore, setHasMore] = useState<any>(false)

  const tenantKey = useSelector(selectTenantKey)

  const loadMore: any = () => {
    // setHasMore(true)
    setHasMore(false)
    // getMore(hasMore)
    getMore(false)
  }

  // useEffect(() => {
  //   if (length < 10) {
  //     setHasMore(false)
  //   }
  //   return () => {
  //     setHasMore(true)
  //   }
  // }, [length, tab])
  return (
    <div className={styles.myVisitorsListContent}>
      {data?.length ? 
        <PullToRefresh
          onRefresh={async () => {
            refresh()
          }}
        >
          <div
            className={styles.visitorsListContentList}
            onTouchMove={() => {
              console.log(11111)
            }}
          >
            {data?.map((v?: any) => {
              return (
                <div style={{ marginBottom: '10px' }}>
                  <Card className={styles.myVisitorsListContentCard}>
                    <div className={styles.myVisitorsListContentTitle}>
                      <div className={styles.myVisitorsListContentTime}>
                        {v?.createTime || '-'}
                      </div>
                      <div
                        className={styles.myVisitorsListContentType}
                        style={{
                          color: getAppointmentStateColor(
                            Number(v?.appointmentState)
                          ),
                        }}
                      >
                        {getAppointmentStateName(Number(v?.appointmentState))}
                      </div>
                    </div>
                    <div className={styles.myVisitorsListContentMain}>
                      <div className={styles.myVisitorsListContentMainContent}>
                        <div className={styles.myVisitorsListContentLeft}>
                          就诊人：{v?.name || '-'}
                        </div>
                        <div className={styles.myVisitorsListContentRight}>
                          预约项目：{v?.appointmentItem || '-'}
                        </div>
                      </div>
                      <div className={styles.myVisitorsListContentMainContent}>
                        <div className={styles.myVisitorsListContentLeft}>
                          预约科室：{v?.appointmentDepartmentName || '-'}
                        </div>
                        <div className={styles.myVisitorsListContentRight}>
                          预约医生：{v?.appointmentDoctorName || '-'}
                        </div>
                      </div>
                      <div className={styles.myVisitorsListContentMainContent}>
                        <div className={styles.myVisitorsListContentTimes}>
                          预约时间：
                          <span style={{ color: '#4bbd76' }}>
                            {v?.appointmentDate +
                              ' ' +
                              v?.appointmentStartTime +
                              '~' +
                              v?.appointmentEndTime || '-'}
                          </span>
                        </div>
                      </div>
                      <div className={styles.myVisitorsListContentMainBottom}>
                        <div className={styles.myVisitorsListContentRemark}>
                          预约备注：{v?.appointmentComment || '-'}
                        </div>
                        {v?.appointmentState == 0 || v?.appointmentState == 9 ? (
                          <div
                            className={styles.myVisitorsListContentButton}
                            onClick={() => {
                              setVisible(true)
                              setId(v?.id)
                            }}
                          >
                            取消
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              )
            })}
          </div>
        </PullToRefresh>
      : (
          <div style={{ backgroundColor: '#fff', width: '100%', height: '100%' }}>
            <img src={noBg} style={{ width: '100%', height: '100%' }} alt="" />
          </div>
      )}
      {/* <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={20} /> */}
      <div className={styles.dialogMain}>
        <Dialog
          visible={visible}
          content='确认取消预约?'
          closeOnAction
          onClose={() => {
            setVisible(false)
          }}
          actions={[
            {
              key: 'cancel',
              text: '取消',
              style: { color: '#999999', borderRight: '1px solid #EEEEEE ' },
            },
            {
              key: 'delete',
              text: '确认',
              style: { color: '#408CFF' },
              onClick: () => {
                dispatch(getMyVisitorsRemove({ tenantKey: tenantKey, id: id }))
                  .then(unwrapResult)
                  .then((v) => {
                    Toast.show({
                      icon: 'success',
                      content: '取消成功',
                      afterClose: () => {
                        setVisible(false)
                        refresh()
                      },
                    })
                  })
              },
            },
          ]}
        />
      </div>
    </div>
  )
}
