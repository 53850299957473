/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-20 17:56:26
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-14 14:22:16
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Card,
  Dialog,
  InfiniteScroll,
  List,
  PullToRefresh,
  SwipeAction,
  SwipeActionRef,
  Toast,
} from 'antd-mobile'
import dd from 'gdt-jsapi'
import moment from 'moment'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createTrue } from 'typescript'
import { AppDispatch } from '../../app/store'
import { DateTimeFormatSimple } from '../../models/datetime'
import { getSexName } from '../../models/gender'
import { sleep } from '../../utils/system'
import styles from './visitorsList.module.css'
import { getPatientRemove } from './visitorsListSlice'
import defaultPeopPng from '../../components/images/defaultPeop.png'

export interface visitorsListProps {
  detailData?: any
  data: any
  length: any
  refresh: () => void
  getMore: (hasMore: any) => void
}

export const VisitorsListCard: React.FC<visitorsListProps> = ({
  detailData,
  data,
  length,
  getMore,
  refresh,
}): ReactElement => {
  const history = useHistory()

  const ref = useRef<SwipeActionRef>(null)

  const dispatch = useDispatch<AppDispatch>()

  const [ids, setIds] = useState<any>()

  const [visible, setVisible] = useState(false)

  const [hasMore, setHasMore] = useState<any>(true)

  const loadMore: any = () => {
    setHasMore(true)
    getMore(hasMore)
  }

  useEffect(() => {
    if (length < 10) {
      setHasMore(false)
    }
    return () => {
      setHasMore(true)
    }
  }, [length])

  return (
    <div className={styles.visitorsListContent}>
      <PullToRefresh
        onRefresh={async () => {
          refresh()
        }}
      >
        <div
          className={styles.visitorsListContentList}
          onTouchMove={() => {
            console.log(11111)
          }}
        >
          {data?.map((v?: any) => {
            return (
              <div style={{ marginBottom: '10px', position: 'relative' }}>
                {v.isDefault === 1 ? <div className={styles.defaultPeop}>
                  <img src={defaultPeopPng} style={{ width: '100%', height: '100%' }} alt="" />
                </div> : ''}
                <SwipeAction
                  ref={ref}
                  closeOnAction={false}
                  closeOnTouchOutside={true}
                  rightActions={[
                    {
                      key: 'delete',
                      text: '删除',
                      color: 'danger',
                      onClick: () => {
                        setVisible(true)
                        setIds(v.id)
                      },
                    },
                  ]}
                >
                  {/* tag区分是否可以点击 */}
                  {detailData?.tag == 1 ? (
                    <Card
                      onClick={() => {
                        sessionStorage.setItem('patientListId', JSON.stringify({ id: v.id }))
                        history.replace({
                          pathname: '/',
                          state: {
                            id: v.id,
                          },
                        })
                        history.go(-1)
                      }}
                    >
                      <div className={styles.content}>
                        <div className={styles.name}>
                          {v.name}（{getSexName(v.sex)}/
                          {v.ageYear > 0
                            ? v.ageYear + '岁'
                            : v.ageMonth + '个月'}
                          ）
                        </div>
                        <div className={styles.phone}>联系方式：{v.phone}</div>
                      </div>
                    </Card>
                  ) : detailData?.tag == 2 ? (
                    <Card
                      onClick={() => {
                        history.replace({
                          pathname: '/appointmentTreatmentPeople',
                          state: {
                            userData: {
                              name: v.name,
                              sex: v.sex,
                              ageYear: v.ageYear,
                              ageMonth: v?.ageMonth,
                              phone: v.phone,
                              id: v.id,
                              birthday: v.birthday,
                            },
                            ...detailData,
                          },
                        })
                        history.go(-1)
                      }}
                    >
                      <div className={styles.content}>
                        <div className={styles.name}>
                          {v.name}（{getSexName(v.sex)}/
                          {v.ageYear > 0
                            ? v.ageYear + '岁'
                            : v.ageMonth + '个月'}
                          ）
                        </div>
                        <div className={styles.phone}>联系方式：{v.phone}</div>
                      </div>
                    </Card>
                    ) : detailData?.tag == 3 ? (
                      <Card
                        onClick={() => {
                          history.push({
                            pathname: '/addPatientPeople',
                            state: {
                              id: v.id
                            },
                          })
                        }}
                      >
                        <div className={styles.content}>
                          <div className={styles.name}>
                            {v.name}（{getSexName(v.sex)}/
                            {v.ageYear > 0
                              ? v.ageYear + '岁'
                              : v.ageMonth + '个月'}
                            ）
                          </div>
                          <div className={styles.phone}>联系方式：{v.phone}</div>
                        </div>
                      </Card>
                    ) : (
                    <Card>
                      <div className={styles.content}>
                        <div className={styles.name}>
                          {v.name}（{getSexName(v.sex)}/
                          {v.ageYear > 0
                            ? v.ageYear + '岁'
                            : v.ageMonth + '个月'}
                          ）
                        </div>
                        <div className={styles.phone}>联系方式：{v.phone}</div>
                      </div>
                    </Card>
                  )}
                </SwipeAction>
              </div>
            )
          })}
        </div>
      </PullToRefresh>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={20} />
      <div className={styles.dialogMain}>
        <Dialog
          visible={visible}
          content='是否确认删除？'
          closeOnAction
          onClose={() => {
            setVisible(false)
          }}
          actions={[
            {
              key: 'cancel',
              text: '取消',
              style: { color: '#999999', borderRight: '1px solid #EEEEEE ' },
            },
            {
              key: 'delete',
              text: '确认',
              style: { color: '#408CFF' },
              onClick: () => {
                dispatch(getPatientRemove({ ids: ids }))
                  .then(unwrapResult)
                  .then((v) => {
                    ref.current?.close()
                    Toast.show({
                      duration: 1000,
                      icon: 'success',
                      content: '操作成功',
                      afterClose: () => {
                        setVisible(false)

                        refresh()
                      },
                    })
                  })
              },
            },
          ]}
        />
      </div>
    </div>
  )
}
