import { API_PREFIX, Request } from './request'

export interface appointmentTimeParams {
  tenantKey?: string
  doctorId?: any
  date?: any
}

export function appointmentTime(params: appointmentTimeParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentDoctorScale4Wechat`,
    method: 'GET',
    params,
  }
}

export interface appointmentTimeDataParams {
  tenantKey?: string
  doctorId?: any
  endDate?: any
  starDate?: any
}

export function appointmentTimeData(
  params: appointmentTimeDataParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentByDate4Wechat`,
    method: 'GET',
    params,
  }
}
