import { API_PREFIX, Request } from './request'

export interface myVisitorsPageParams {
  current?: string
  size?: any
  tenantKey?: any
  openid?: any
  flag: any
}

export function myVisitorsPage(params: myVisitorsPageParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentRecordByOpenid4Wechat`,
    method: 'GET',
    params,
  }
}

export interface myVisitorsRemoveParams {
  tenantKey?: any
  id?: any
}

export function myVisitorsRemove(body: myVisitorsRemoveParams): Request {
  return { 
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/appointmentRecordCancel4Wechat`,
    method: 'POST',
    body,
  }
}
