/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-16 13:55:31
 * @LastEditors: sj
 * @LastEditTime: 2021-12-07 09:42:15
 */

import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import appReducer from './appSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
export type AppThunkApi<RejectValue = void> = {
  state: RootState
  dispatch: AppDispatch
  rejectValue: RejectValue
}
