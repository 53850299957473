import { ArticalFirst } from "./articalFirst"
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { ArticalSecond } from "./articalSecond"
import { ArticalThird } from "./articalThird"
import { useEffect } from "react"

export const Artical = () => {

    const history = useHistory()

    const state: any = history.location.state

    const id  = state?.id || localStorage.getItem('articalId')


    return (
        <div style={{ width: '100vw', height: '100vh', overflow: 'scroll' }}>
            {
                id == 1 ? <ArticalFirst /> : id == 2 ? <ArticalSecond /> : <ArticalThird />
            }
        </div>
    )
}