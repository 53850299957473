import { API_PREFIX, Request } from './request'

export interface onlineAppointmentParams {
  appid?: any
  tenantKey?: any
  date?: any
}

// 号源信息
export function onlineAppointmentList(
  params: onlineAppointmentParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentDoctorScaleByDate4Wechat`,
    method: 'GET',
    params,
  }
}

export interface onlineAppointmentDoctorParams {
  appid?: any
  tenantKey?: any
  endDate?: any
  starDate?: any
}

// 号源信息
export function onlineAppointmentDoctorCardList(
  params: onlineAppointmentDoctorParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getTenantAppointmentDoctorScale4Wechat`,
    method: 'GET',
    params,
  }
}

export interface onlineAppointmentTimeParams {
  appid?: string
  tenantKey?: string
  endDate?: any
  starDate?: any
}

export function onlineAppointmentTimeList(
  params: onlineAppointmentTimeParams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getTenantAppointmentByDate4Wechat`,
    method: 'GET',
    params,
  }
}
