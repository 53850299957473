/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:33:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 09:18:16
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
    Card,
    DotLoading,
    Toast
} from 'antd-mobile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './mine.module.css'
import { useLocation } from 'react-router-dom'
import wx from 'weixin-js-sdk' // 引入微信js-sdk
import settingPng from '../../components/images/setting.png'
import guanliPng from '../../components/images/guanli.png'
import recordPng from '../../components/images/record.png'
import couponsPng from '../../components/images/coupons.png'
import { selectTenantKey, selectOpenId, setCredentials, selectAccessToken, selectAppId } from '../../app/appSlice'
import {
    getHomeDetail,
    getDefaultPatient,
    getDefaultPatientCode,
    updateDeafaultPatient
} from '../home/HomeSlice'
import { AddCircleOutline } from 'antd-mobile-icons'
import { Share } from "../../components/Share/share";

export const Mine = () => {
    const dispatch = useDispatch<AppDispatch>()

    const history = useHistory()

    const tagList = ['霓虹', '的话v就', '的话v就', '的话v就']

    const [visible, setVisible] = useState(false)

    const data: any = history.location.state || JSON.parse(sessionStorage.getItem('patientListId') as any)

    const [homeData, setHomeData] = useState<any>()

    const [doctorDetail, setDoctorDetail] = useState<any>()

    const location = useLocation()

    const appId = useSelector(selectAppId)

    const [isLoading, setIsLoading] = useState(false)

    const tenantKey = useSelector(selectTenantKey)

    const openid = useSelector(selectOpenId)

    const [qrCode, setQrCode] = useState<any>()

    const [defaultPeople, setDefaultPeople] = useState<any>()

    const [tabList, setTabList] = useState<any>([])

    useEffect(() => {
        if (tenantKey) {
            dispatch(getHomeDetail({ tenantKey: tenantKey }))
                .then(unwrapResult)
                .then((res: any) => {
                    if (JSON.stringify(res) == '{}') {
                        alert('机构在线服务正在建设中，敬请期待！')
                        window.close()
                    } else {
                        setHomeData(res)
                        if (!res?.menuListView?.includes('3')) {
                            setTabList([{ id: 1, name: '就诊人管理' }, { id: 2, name: '预约记录' }])
                        } else {
                            setTabList([{ id: 1, name: '就诊人管理' }, { id: 2, name: '预约记录' }, { id: 3, name: '优惠券' }])
                        }
                    }
                }).catch(() => {
                })
        }
    }, [tenantKey])

    useEffect(() => {
        Toast.show({
            content: '加载中...',
            icon: 'loading',
            duration: 5000,
            maskClickable: false
        })
        setIsLoading(true)
        dispatch(data?.id ? updateDeafaultPatient({ tenantKey: tenantKey, openid: openid, id: data?.id }) : getDefaultPatient({ tenantKey: tenantKey, openid: openid }))
            .then(unwrapResult)
            .then((v: any) => {
                setDefaultPeople(v)
                if (v?.patientId) {
                    dispatch(getDefaultPatientCode({ content: v?.patientId }))
                        .then(unwrapResult)
                        .then((res) => {
                            setQrCode('data:image/png;base64,' + res)
                            setIsLoading(false)
                            Toast.clear()
                        })
                    localStorage.setItem('patientId', v?.patientId)
                } else {
                    localStorage.removeItem('patientId')
                    setIsLoading(false)
                    Toast.clear()
                }
            })
    }, [])


    return (
        <div className={styles.home}>
            <Share title={'我的'} param={''} />
            <div className={styles.header}>
                <div className={styles.title}>{homeData?.name}</div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Card className={styles.peopleContent}>
                        {
                            isLoading ? (
                                <></>
                            ) :
                                defaultPeople?.patientId ? (
                                    <div className={styles.people}>
                                        <div className={styles.peopleContentLeft}>
                                            <div className={styles.peopleNameBox}>
                                                <div className={styles.peopleName}>{defaultPeople?.name}</div>
                                                <div className={styles.peopleNameChange} onClick={() => {
                                                    history.push({
                                                        pathname: '/visitorsList',
                                                        state: {
                                                            tag: 1
                                                        }
                                                    })
                                                }}>
                                                    <div className={styles.changeIcon}></div>
                                                    <div className={styles.changeText}>切换</div>
                                                </div>
                                            </div>
                                            <div className={styles.visitCard}>
                                                <div className={styles.visitCardTitle}>就诊卡</div>
                                                <div className={styles.visitCardValue}>{defaultPeople?.patientId}</div>
                                            </div>
                                        </div>
                                        <div className={styles.peopleContenRight} onClick={() => {
                                            history.push({
                                                pathname: '/patientDetail',
                                                state: {
                                                    id: defaultPeople?.id
                                                }
                                            })
                                        }}>
                                            <img src={qrCode} style={{ width: '100%', height: '100%' }} alt="" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.noPeople} onClick={() => {
                                        history.push({
                                            pathname: '/addPatientPeople',
                                        })
                                    }}>
                                        <AddCircleOutline fontSize={18} color='#999' />
                                        <span style={{ marginLeft: '10px' }}>添加/完善就诊人</span>
                                    </div>
                                )
                        }
                    </Card>
                </div>
            </div>
            <div style={{
                width: '100%', display: 'flex', justifyContent: 'center', position: 'relative',
                top: '-25px'
            }}>
                <Card className={styles.titleContent}>
                    <div className={styles.titleTagContent}>
                        <div className={styles.actionTabs}>
                            {tabList.map((v: any) => {
                                return (
                                    <div className={styles.actionTabsItem} onClick={() => {
                                        if (v.id === 1) {
                                            history.push({
                                                pathname: '/visitorsList',
                                                state: {
                                                    tag: 3
                                                }
                                            })
                                        } else if (v.id === 2) {
                                            history.push({
                                                pathname: '/myVisitorsList',
                                            })
                                        } else if (v.id === 3) {
                                            if (localStorage.getItem('patientId')) {
                                                history.push({
                                                    pathname: '/coupons',
                                                })
                                            } else {
                                                Toast.show({
                                                    content: '请先完善就诊人',
                                                    // icon: 'loading',
                                                    duration: 2000,
                                                    maskClickable: true
                                                })
                                            }
                                        } else if (v.id === 4) {
                                            // history.push({
                                            //     pathname: '/setting',
                                            //     state: defaultPeople
                                            // })
                                        }

                                    }}>
                                        <div className={v.id === 1 ? styles.guanliItem
                                            : v.id === 2 ? styles.recordItem
                                                : v.id === 3 ? styles.couponsItem
                                                    : v.id === 4 ? styles.settingItem
                                                        : ''}>
                                            <img src={v.id === 1 ? guanliPng
                                                : v.id === 2 ? recordPng
                                                    : v.id === 3 ? couponsPng
                                                        : v.id === 4 ? settingPng
                                                            : ''} style={{ width: '28px', height: '28px' }} alt="" />
                                        </div>
                                        <div className={styles.actionTabsItemTitle}>{v.name}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Card>
            </div>
            {/* <div className={styles.centerImg}>退出登录</div> */}
        </div>
    )
}
