import { API_PREFIX, Request } from './request'
import { getWechatJsSdkSignature } from '../features/home/HomeSlice'

// 获取首页数据
export function homeDetail(appId: any): Request {
  return {
    url: `${API_PREFIX}/blade-user/clinic/detailForWechat`,
    method: 'GET',
    params: appId,
  }
}
// 获取首页医生数据
export function doctorList(appId: any): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/listDoctorByTenantKey`,
    method: 'GET',
    params: appId,
  }
}

export interface getOpenIdParams {
  code?: string
  appid: any
}

export interface getWechatJsSdkSignatureParams {
  currUrl: string
  appid: string
}
export function _getWechatJsSdkSignature(
  params: getWechatJsSdkSignatureParams
): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechat/tenant/getWechatJsSdkSignatureGET`,
    // url: `/wechat/tenant/getWechatJsSdkSignatureGET`,
    method: 'GET',
    params,
  }
}

export function _getOpenIdByCode(params: getOpenIdParams): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechat/third/getOpenIdByCode`,
    method: 'GET',
    params,
  }
}


//默认就诊人
export function _getDeafaultPatient(params: { openid: string, tenantKey: string }): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechatpatient/getDefaultPatient`,
    method: 'GET',
    params,
  }
}


//默认就诊人
export function _getDeafaultPatientCode(params: { content: string }): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechat/tenant/getQrcode`,
    method: 'GET',
    params,
  }
}

//更新就诊人
export function _updateDeafaultPatient(params: { tenantKey: string; id: string, openid: string }): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechatpatient/getPatient`,
    method: 'GET',
    params,
  }
}