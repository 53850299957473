import styles from './articalFirst.module.css'
import {Share} from "../../components/Share/share";
import React from "react";
import { LeftOutline } from 'antd-mobile-icons'
import { useHistory } from 'react-router-dom'

export const ArticalSecond = () => {

    const history = useHistory()

  return (
      <div className={styles.articalFirst}>
          <Share title={'健康科普丨医生才知道的 40 条健康知识，让你轻松掌握！'} param={'&articalId=2'}/>
          <div className={styles.title}>
              <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
            健康科普丨医生才知道的 40 条健康知识，让你轻松掌握！</div>
          {/*<div style={{ marginBottom: '16px', color: '#999' }}>乐陵妇幼保健院  2024-02-22 16:24 山东</div>*/}
          <div className={styles.info}>因为不了解，很多时候人们在无意识的情况下，就做了毁健康的事，今天总结了40条医生才知道的健康知识，每个人都应该看看！</div>
          <div className={styles.type}>疾病预防</div>
          <div className={styles.titleThird}>1.老人常咬到舌头，可能是中风前兆</div>
          <div className={styles.content}>偶尔咬到舌头是正常现象，不是想吃肉了。经常会被咬着，常见出现的问题可能是中风，脑神经受压迫使舌不能灵活运动。</div>
          <div className={styles.titleThird}>2.
              打呼噜，每年憋死几十万人！</div>
          <div className={styles.content}>数据显示，每100个人中，就有3~5个人打呼噜，而每5个打呼噜的人中，就有一个是阻塞性睡眠呼吸暂停综合征。会使人体长期缺氧，最终导致全身多系统功能损害，严重时甚至会导致睡眠中猝死。</div>
          <div className={styles.titleThird}>3.身上的包块，不痛的比痛的更危险！</div>
          <div className={styles.content}>身上有了不明原因的肿块，比如在乳房，有红肿热痛症状的往往只是单纯的炎症，但如果这个块不痛，而且活动性不好、难以推离原位，那么恶性的可能性会暴涨。一旦发现不明原因、不痛的肿块，马上去医院。</div>
          <div className={styles.titleThird}>4.
              骨刺，是人体的正常产物！</div>
          <div className={styles.content}>骨刺是人体“老了”的正常产物，是正常病理变化，大多数没有症状的骨刺是不需要治疗的。吃任何药物都不会消除骨刺，只是可以缓解疼痛。</div>
          <div className={styles.titleThird}>5.老人和孩子要接种流感疫苗！</div>
          <div className={styles.content}>流感疫苗是使用每年最流行的三个病毒株做成疫苗，也就是说，注射之后人会获得对于最流行的几种感冒的抗性。注射疫苗尽管不能避免得感冒，但是却能避过最厉害的那几种。</div>
          <div className={styles.titleThird}>6.糖尿病的原因并不是因为吃糖多！</div>
          <div className={styles.content}>I型糖尿病的主因是遗传。II型的主要原因是肥胖。当然，如果血糖升高，要少吃糖。</div>
          <div className={styles.titleThird}>7.胃溃疡跟吃什么东西关系不大！</div>
          <div className={styles.content}>胃溃疡的主因是幽门螺旋杆菌感染，主要的传播渠道是中国特色的共餐和很多人嚼东西喂孩子，幽门螺旋杆菌感染是可以通过抗生素治愈的。</div>
          <div className={styles.titleThird}>8.最有效的快速止咳法是吃糖！</div>
          <div className={styles.content}>在咳嗽时，含一块硬糖，不断吞下的糖浆可以在喉部形成保护层，减轻喉部刺激，喝蜂蜜水也可以，不过不如含着糖来得持久。临床上主要使用的镇咳药水多依靠可待因麻痹神经，大量饮用有成瘾风险。</div>
          <div className={styles.titleThird}>9.
              感冒大多是“不治之症”，不治也能好！</div>
          <div className={styles.content}>美国威斯康星大学医学与公共卫生院2010年发布的《感冒白皮书》指出：
              第一，超过100种病毒都可以引发感冒；目前世界上，还没有针对感冒病毒的抗病毒感冒药。第二，感冒药无法治疗感冒，因为感冒药杀不死病毒，能杀死病毒的，只有人体的免疫系统。第三，感冒的症状会持续一星期左右，无论服药与否，都会自动痊愈。</div>
          <div className={styles.titleThird}>10.
              你的心情怎么样，你的健康就怎么样！</div>
          <div className={styles.content}>美国曾有一项调查发现，75%的颈椎疼痛、80%的头痛、99%的腹胀以及90%的疲劳都由过度的情绪引起，人体的每个脏器都与对应的情绪息息相关。</div>
          <div className={styles.type}>安全用药</div>
          <div className={styles.titleThird}>1.
              38.5℃，也可等等再吃退烧药！</div>
          <div className={styles.content}>日前，中国科学院生物化学与细胞生物学研究所陈剑峰研究组最新发表在国际免疫学权威期刊《免疫》上的一项研究提示，发烧后可以在病人身体条件许可的情况下，让体温维持在38.5℃以上一段时间，然后再吃退烧药，这样可以帮助提高清除病原体能力。</div>
          <div className={styles.titleThird}>2.复合感冒药，间隔4小时再用！</div>
          <div className={styles.content}>数感冒药都含有解热镇痛的化学成分乙酰氨基酚，如同时服用多种感冒药或是中西药物混合使用，总剂量会在不知不觉中超量，造成肝脏受损。</div>
          <div className={styles.titleThird}>3.
              天然维生素，不比合成维生素更好！</div>
          <div className={styles.content}>天然维生素和合成维生素在作用上没有任何区别，相反，天然维生素在生产提取过程中还更可能引入污染，及混入不纯物质。补充维生素的话，药店几块钱一百片那种足够了。</div>
          <div className={styles.titleThird}>4.没有哪种药对白内障有逆转作用！</div>
          <div className={styles.content}>被临床使用的白内障眼药有不少，但没有哪种眼药对白内障有逆转作用。也就是说，这些眼药只能在一定程度上缓解白内障的发展，但无法做到逆转。治白内障，医生的建议基本都是手术治疗。</div>
          <div className={styles.titleThird}>5.
              不管服用任何药物时，都要忌吸烟！</div>
          <div className={styles.content}>烟中含有的烟碱会加快肝脏降解药物的速度，服药时吸烟会导致血液中的药物浓度下降，使药效难以充分发挥。所以在服用任何药物后的30分钟内都不要吸烟。</div>
          <div className={styles.titleThird}>6.
              喝完糖浆，5分钟内不要喝水！</div>
          <div className={styles.content}>止咳糖浆是个药物例外，不要用水送服，喝完也不要着急喝水，因为喝水会稀释药液，减弱止咳作用。多种药物同时服用，应最后服止咳糖浆。</div>
          <div className={styles.titleThird}>7.
              滴眼药水，不要滴在眼球！</div>
          <div className={styles.content}>许多患者滴眼药水时常对着黑眼球(角膜)，这是不正确的。应该将眼药水滴入下眼睑，也可以点到下方的白眼球上。</div>
          <div className={styles.titleThird}>8.一日三次≠早中晚饭后</div>
          <div className={styles.content}>一般药品说明书上“一日三次”指每8小时服用一次。比如，抗生素、止痛药、激素类药等一般每隔8小时服一次药；而降糖药、调脂药、胃药等，则需按照三餐时间服用。记得认真阅读药品说明书，遵医嘱服药。</div>
          <div className={styles.titleThird}>9.
              辨别抗生素，记住10个字！</div>
          <div className={styles.content}>我们都知道滥用抗生素有害，但是很多人并不清楚到底哪些药属于抗生素的范畴，记住10个字！“头孢”“沙星” “霉素”“硝唑”“西林”“磺胺” “环素”“米星” “康唑”“培南”。</div>
          <div className={styles.titleThird}>10.润喉片，没症状就别吃！</div>
          <div className={styles.content}>把润喉片当成糖果，滥用会大量杀死口腔细菌，造成菌群失调，导致真菌感染，形成口腔溃疡，诱发口腔疾病。没有病症时最好别吃。</div>
          <div className={styles.type}>三餐营养</div>
          <div className={styles.titleThird}>1.食品一成“精”，肯定不是好东西！</div>
          <div className={styles.content}>比如，糖精是煤焦油提取出来的，现在大家都不吃糖精了；瘦肉精吃了会引起心律失常。健康的饮食，多来自天然食材，还要注意烹调方法。</div>
          <div className={styles.titleThird}>2.
              补身体，只喝汤是没有用的！</div>
          <div className={styles.content}>炖得再久的汤，也仅仅是非常稀薄的脂肪和水溶性氨基酸溶液，主要的营养成分还是留在肉里。骨头汤也并不能补钙，就算用高压锅炖几个小时，汤里的钙质也只会比自来水略高点，且脂肪含量高。
</div>
          <div className={styles.titleThird}>3.
              香蕉通便，效果还比不上苹果梨！</div>
          <div className={styles.content}>香蕉润肠？只有熟透的香蕉才能有上述功能，生香蕉反而会加重便秘。熟透的香蕉也并非预防便秘的上等选择，高膳食纤维水果才是通便的“利器”，比如苹果、长把梨。</div>
          <div className={styles.titleThird}>4.“健康零食”，基本就三种！</div>
          <div className={styles.content}>健康零食”基本就三种:水果、坚果和酸奶。注意，水果、坚果不能吃多，酸奶选原味、添加少的。</div>
          <div className={styles.titleThird}>5.
              面包牛奶，不是健康早餐！</div>
          <div className={styles.content}>面包牛奶主要问题是含糖量较高，血糖很快升上来，又很快降下去，易疲倦。面包往往经过精加工，很多营养流失掉，营养不丰富。</div>
          <div className={styles.titleThird}>6.
              看到食物相克，都别相信！</div>
          <div className={styles.content}>什么草酸、乳酸、鞣酸或VC，只要碰上钙就不吸收？人不是玻璃试管，不会吃点酸就变酸，吃碱就变碱了。也不要觉得这个那个不能同吃，均衡地吃饭，愉快过日子，就得了！</div>
          <div className={styles.titleThird}>7.清淡饮食是指少油盐的饮食！</div>
          <div className={styles.content}>注意这里是少油盐，不是说每天清水煮菜叶最健康，必要的脂肪和无机盐也是人体必要的营养素。</div>
          <div className={styles.titleThird}>8.打果汁，不如打蔬菜汁！</div>
          <div className={styles.content}>一个苹果或梨，最多也就榨半杯果汁;喝两三杯果汁，就等于喝进去了4-6个水果的糖分!不如用打浆机把大量蔬菜和少量水果一起打碎成浆，连渣子一起喝下去。</div>
          <div className={styles.titleThird}>9.
              主食吃得少，会减寿！</div>
          <div className={styles.content}>《柳叶刀》子刊《柳叶刀公共卫生》杂志刊登的研究显示：主食吃得太少，比吃得过多更危险！记住，每天最少半斤粮！</div>
          <div className={styles.titleThird}>10.
              家里掌勺的，做饭记住四句口诀！</div>
          <div className={styles.content}>饭中有豆，再加薯（红薯、芋头、山药等膳食纤维丰富）；菜中有叶，再添果（核桃、花生碎等）；肉中有菇，配点笋；汤中有藻（海带、紫菜、裙带菜等）再飘点花（菊花、桂花、槐花等）。</div>
          <div className={styles.type}>日常生活</div>
          <div className={styles.titleThird}>1.三类人，不适合泡脚养生！</div>
          <div className={styles.content}>糖尿病足患者、静脉曲张与静脉血栓患者、动脉闭塞缺血患者都不适合泡脚养生。</div>
          <div className={styles.titleThird}>2.
              擦屁股，要从前往后！</div>
          <div className={styles.content}>无论大便还是小便，女性都要从前往后擦。</div>
          <div className={styles.titleThird}>3.掉发超过100根算脱发！</div>
          <div className={styles.content}>正常人每天都会脱落约50~100根头发。但如果每天掉发超过100根，并连续3个月以上，要警惕脱发。</div>
          <div className={styles.titleThird}>4.
              医学上，根本没有“宿便”！</div>
          <div className={styles.content}>我们的直肠平时是空的，并不积存大便，一般只有少量粪便会堆积在结肠末端准备排出体外。所以没有“宿便”一说，更没有“洗肠清宿便”一说。</div>
          <div className={styles.titleThird}>5.各种补品，千万不要瞎吃！</div>
          <div className={styles.content}>有人觉得吃补品没坏处，但对有些人来说，吃补品可能火上浇油。比如说肠癌，本即与营养过剩有一定关系，再吃补品，就是火上浇油。遗憾的是，盲目进补的人不在少数。要防病，须科学食用补品。</div>
          <div className={styles.titleThird}>6.醉酒后，一定要侧卧睡！</div>
          <div className={styles.content}>醉酒后要用侧卧位，这样就不会发生气道堵塞。侧卧时，一定要给后背加一点枕头或者阻挡物，避免醉酒者自己翻成仰躺位。而且要隔一会儿看看，当他完全清醒就可以放心了。</div>
          <div className={styles.titleThird}>7.
              吃感冒药后，千万别开车！</div>
          <div className={styles.content}>吃感冒药开车小心“药驾”，服用感冒药后24小时内最好别开车。九成的感冒药中都含有扑尔敏成分，这种药学成分易引起犯困、嗜睡，出现“药驾”。</div>
          <div className={styles.titleThird}>8.
              不存在保大人还是保孩子的说法！</div>
          <div className={styles.content}>一般遇到问题时，医生首先考虑的肯定是大人，因为只要孩子没生出来，大人的问题一定会影响到孩子，这没什么好选择的。</div>
          <div className={styles.titleThird}>9.
              蹲厕久了，未必是有便意！</div>
          <div className={styles.content}>很多人总觉得“便便没排干净”，一直蹲啊蹲，结果蹲出了痔疮。其实，蹲久了的便意可能只是直肠粘膜松弛、垂下来而已，因为蹲姿和用力拉都会增加肚子里压力，容易让粘膜脱垂。记住，蹲坑别超10分钟。
</div>
          <div className={styles.titleThird}>10.
              对于孩子，一粒花生米就等于一颗子弹！</div>
          <div className={styles.content}>刚毕业幼师拿了一把小花生米哄男孩儿，孩子一边抽泣，一边把花生米放到嘴里。花生米误入气道，引发窒息，送到急诊室时，孩子的心跳、呼吸已经停了。经过一系列的急救，但回天乏术。异物窒息要学会最基本的急救方法——海姆立克法。</div>
    </div>
  );
};