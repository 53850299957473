/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:33:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-01 11:21:43
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  CapsuleTabs,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Grid,
  Input,
  JumboTabs,
  List,
  PullToRefresh,
  Radio,
  Space,
  Swiper,
  TextArea,
  Toast,
} from 'antd-mobile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './addPatientPeople.module.css'
import rightIconArrow from '../../components/images/rightIconArrow.png'
import { getSexName } from '../../models/gender'
import { DateTimeFormatSimple } from '../../models/datetime'
import { getAddPatientListSubmit, updatePatientListSubmit } from './addPatientPeopleSlice'
import {selectAppId, selectOpenId, selectTenantKey} from '../../app/appSlice'
import {
  getDefaultPatient,
  updateDeafaultPatient
} from '../home//HomeSlice'
import {Share} from "../../components/Share/share";
import { LeftOutline } from 'antd-mobile-icons'

export const AddPatientPeople = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [pickerVisible, setPickerVisible] = useState(false)

  const [ageParam, setAgeParam] = useState<any>()

  const history = useHistory()

  const [form] = Form.useForm()

  const openid = useSelector(selectOpenId)

  const appid = useSelector(selectAppId)

  const tenantKey = useSelector(selectTenantKey)

  const detailData:any = history.location.state //区分跳不跳

  const [isDefault, setIsDefault] = useState<any>(false)

  const [defaultPeople, setDefaultPeople] = useState<any>()

  const now = new Date()

  const minData: any = new Date(moment('1900/1/1').format('YYYY/MM/DD'))

  useEffect(() => {
    form.setFieldsValue({
      sex: '0',
    })
  }, [])

  useEffect(() => {
    console.log(ageParam, 'ageParam')
  }, [ageParam])

  useEffect(() => {
    if (detailData?.id) {
      dispatch(updateDeafaultPatient({ tenantKey: tenantKey, openid: openid, id: detailData?.id }))
        .then(unwrapResult)
        .then((res: any) => {
          setDefaultPeople(res)
          console.log(moment(res?.birthday).toDate());
          
          form.setFieldsValue({
            name: res?.name,
            birthday: moment(res?.birthday).toDate(),
            sex: String(res?.sex),
            phone: res?.phone
          })
          setIsDefault(res.isDefault == 0 ? false : true)
        })
    }

  }, [detailData])

  const getIdCardInfo = (years: any, months: any, days: any) => {
    const date = new Date()

    const getMonth = date.getMonth() + 1 //当前月份
    const month = months //身份证上的月份

    const getFullYear = date.getFullYear() //当前年份
    const year = years //身份证上的年份

    const birthday = days

    //  身份证上的年月日
    if (years && months && days) {
      if (getMonth - month > 0) {
        console.log()
        setAgeParam({
          ageYear: getFullYear - year,
          ageMonth: getMonth - month,
        })
      } else if (getMonth - month < 0) {
        setAgeParam({
          ageYear: getFullYear - year - 1,
          ageMonth: 12 - (month - getMonth),
        })
      } else if (getMonth - month === 0) {
        setAgeParam({
          ageYear: getFullYear - year,
          ageMonth: 0,
        })
        form.setFieldsValue({})
      }
    }
  }

  return (
    <div className={styles.addPatientPeopleContent}>
      <div className={styles.backIconBox}>
        <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
      </div>
      <Share title={'添加患者'} param={''}/>
      <div className={styles.cardOne}>
        <Form
          layout='horizontal'
          form={form}
          onFinish={(v) => {
            const rule = new RegExp(
              /^\d{3}-\d{8}|\d{4}-\d{7}|^(1[3,4,5,6,7,8,9][0-9]{9})$/ ||
                /^w+([-+.]w+)*@w+([-.]w+)*.w+([-.]w+)*$/
            )
            if (rule.test(v?.phone)) {
              const params = {
                ...ageParam,
                ...v,
                birthday: moment(v.birthday).format(DateTimeFormatSimple),
                openid: openid,
                isDefault: isDefault ? 1 : 0,
                tenantAppId: appid,
                tenantKey: tenantKey,
                id: detailData?.id || undefined
              }
              dispatch(detailData?.id ? updatePatientListSubmit(params) : getAddPatientListSubmit(params))
                .then(unwrapResult)
                .then((v) => {
                  Toast.show({
                    icon: 'success',
                    content: '操作成功',
                    duration: 1000,
                    afterClose: () => {
                      history.replace({
                        pathname: '/visitorsList',
                        state: detailData,
                      })
                      history.go(-1)
                    },
                  })
                })
            } else {
              Toast.show({
                content: '请输入正确的手机格式',
              })
            }
          }}
        >
          <Form.Item
            name='name'
            label='就诊人姓名'
            rules={[{ required: true, message: '就诊人信息不能为空' }]}
          >
            <Input onChange={console.log} placeholder='请输入' maxLength={20} />
          </Form.Item>
          <Form.Item
            name='birthday'
            label='出生日期'
            trigger='onConfirm'
            rules={[{ required: true, message: '出生日期不能为空' }]}
            // onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
            //   datePickerRef.current?.open()
            // }}
          >
            <DatePicker
              min={minData}
              max={now}
              visible={pickerVisible}
              onClose={() => {
                setPickerVisible(false)
              }}
            >
              {(value) =>
                value ? (
                  <div
                    style={{
                      fontSize: '15px',
                    }}
                    onClick={() => {
                      setPickerVisible(true)
                    }}
                  >
                    {moment(value).format(DateTimeFormatSimple)}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                    onClick={() => {
                      setPickerVisible(true)
                    }}
                  >
                    <span style={{ fontSize: '15px', color: '#cccccc' }}>
                      请选择
                    </span>
                    <img
                      src={rightIconArrow}
                      alt=''
                      style={{
                        width: '18px',
                        height: '18px',
                        color: '#cccccc',
                      }}
                    ></img>
                  </div>
                )
              }
            </DatePicker>
          </Form.Item>
          <Form.Item
            name='sex'
            label='性别'
            rules={[{ required: true, message: '性别不能为空' }]}
          >
            <Radio.Group>
              <Space>
                <Radio value='1'>男</Radio>
                <Radio value='2'>女</Radio>
                <Radio value='0'>未知</Radio>
              </Space>
            </Radio.Group>
            {/* <Input onChange={console.log} placeholder='请输入' /> */}
          </Form.Item>
          <Form.Item
            name='phone'
            label='联系方式'
            rules={[{ required: true, message: '联系方式不能为空' }]}
          >
            <Input onChange={console.log} placeholder='请输入' maxLength={13} />
          </Form.Item>
        </Form>
        <div className={styles.default}>
          <span>设置为默认就诊人</span>
          <Checkbox checked={isDefault}  onChange={(v) => {
            setIsDefault(v)
          }} />
        </div>
      </div>
      <div className={styles.bottomButton} onClick={() => {}}>
        <Button
          className={styles.bottomButtonText}
          type='submit'
          onClick={() => {
            const data = form.getFieldsValue()
            console.log(data);
            
            const submitData = {
              name: data?.name ? data?.name : '',
              birthday: data?.birthday ? data?.birthday : '',
              sex: data?.sex ? data?.sex : '0',
              phone: data?.phone ? data?.phone : '',
            }
            getIdCardInfo(
              moment(submitData?.birthday).format('YYYY'),
              moment(submitData?.birthday).format('MM'),
              moment(submitData?.birthday).format('DD')
            )
            if (
              !submitData?.name ||
              !submitData?.birthday ||
              !submitData?.sex ||
              !submitData?.phone
            ) {
              Toast.show({
                content: '请填写必填项',
                afterClose: () => {},
              })
            } else {
              form.submit()
            }
          }}
        >
          {detailData?.id ? '保存' : '确认新增'}
        </Button>
      </div>
    </div>
  )
}
