/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 17:27:39
 * @LastEditors: sj
 * @LastEditTime: 2021-11-23 09:24:20
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { send } from '../../app/appSlice'
import { AppThunkApi, RootState } from '../../app/store'
import {
  patientPage,
  patientPageParams,
  patientRemove,
  patientRemoveParams,
} from '../../services/visitorsList'

// 就诊人列表查询
export const getPatientList = createAsyncThunk<
  void,
  patientPageParams,
  AppThunkApi
>('patientList/getPatientList', async (params, api) => {
  return send(patientPage(params), api)
})

// 删除
export const getPatientRemove = createAsyncThunk<
  void,
  patientRemoveParams,
  AppThunkApi
>('patientList/patientRemove', async (params, api) => {
  return send(patientRemove(params), api)
})