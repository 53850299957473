/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 11:45:11
 * @LastEditors: linxi
 * @LastEditTime: 2021-11-30 16:29:15
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { List, SearchBar, Tabs } from 'antd-mobile'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch } from '../../app/store'
import { getPatientList } from '../visitorsList/visitorsListSlice'
import { MyVisitorsListCard } from './myVisitorsListCard'
import styles from './myVisitorsList.module.css'
import { getMyVisitorsPage } from './myVisitorsListSlice'
import { selectTenantKey, selectOpenId } from '../../app/appSlice'
import {Share} from "../../components/Share/share";
import { LeftOutline } from 'antd-mobile-icons'


export const MyVisitorsList = () => {
  const history = useHistory()

  const dispatch = useDispatch<AppDispatch>()

  const [queries, setQueries] = useState<any>({
    current: 0,
    size: 10,
  })

  const [length, setLength] = useState<any>()

  const [nextCurrent, setNextCurrent] = useState(0)

  const [flag, setFlag] = useState<any>()

  const [tab, setTab] = useState('1')

  const [data, setData] = useState<any>([])

  const tenantKey = useSelector(selectTenantKey)

  const openid = useSelector(selectOpenId)

  useEffect(() => {
    if (tab) {
      dispatch(
        getMyVisitorsPage({
          tenantKey: tenantKey,
          openid: openid,
          flag: tab,
        })
      )
        .then(unwrapResult)
        .then((value: any) => {
          setData(value)
          console.log(value, 'value')
          // setFlag(undefined)
          // setNextCurrent(value?.current)
          // setLength(value?.records?.length)
          // if (value?.current === 1) {
          //   setData(value?.records)
          // } else {
          //   if (queries?.current > nextCurrent) {
          //     setData([...data, ...value?.records])
          //   }
          // }
        })
    }
  }, [dispatch, tab])

  // useEffect(() => {
  //   if (flag === true) {
  //     if (length < 10) {
  //       return
  //     } else {
  //       setQueries({
  //         ...queries,
  //         current: queries?.current + 1,
  //       })
  //     }
  //   }
  // }, [flag, length])

  const refresh = () => {
    dispatch(
      getMyVisitorsPage({
        tenantKey: tenantKey,
        openid: openid,
        flag: tab,
      })
    )
      .then(unwrapResult)
      .then((value: any) => {
        setData(value)
      })
  }

  useEffect(() => {
    setData([])
  }, [tab])

  return (
    <div className={styles.myVisitorsList}>
      <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
      <Share title={'预约记录'} param={''}/>
      <Tabs
        onChange={(v: any) => {
          console.log(v)
          setTab(v)
          setQueries({
            ...queries,
            current: 1,
          })
        }}
      >
        <Tabs.Tab title='进行中' key='1'>
          <MyVisitorsListCard
            tab={tab}
            length={length}
            getMore={(v) => {
              setFlag(v)
            }}
            data={data}
            refresh={() => {
              refresh()
            }}
          ></MyVisitorsListCard>
        </Tabs.Tab>
        <Tabs.Tab title='全部' key='0'>
          <MyVisitorsListCard
            tab={tab}
            length={length}
            getMore={(v) => {
              setFlag(v)
            }}
            data={data}
            refresh={() => {
              refresh()
            }}
          ></MyVisitorsListCard>
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}
