/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-20 17:56:26
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-09 14:37:39
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Card, InfiniteScroll, List, Toast, DotLoading } from 'antd-mobile'
import PullToRefresh, {
  PullStatus,
} from 'antd-mobile/es/components/pull-to-refresh'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectAppId, selectTenantKey } from '../../app/appSlice'
import { AppDispatch } from '../../app/store'
import boyHeadImg from '../../components/images/headImgBoy.png'
import girlHeadImg from '../../components/images/headImgGirl.png'
import styles from './onlineAppointment.module.css'
import { getOnlineAppointmentDoctorCardList } from './onlineAppointmentSlice'
import noBg from '../../components/images/noBg.svg'

export interface onlineAppointmentDoctorCard {
  data?: any
  number?: any
  length?: any
  tab?: any
}

export const OnlineAppointmentDoctorCard: React.FC<
  onlineAppointmentDoctorCard
> = ({ data, number, length, tab }): ReactElement => {
  const history = useHistory()

  const appid = useSelector(selectAppId)

  const queryParams = new URLSearchParams(window.location.search)
  const state = queryParams.get('state')

  const tenantKey = useSelector(selectTenantKey)

  const dispatch = useDispatch<AppDispatch>()

  const [dataList, setDataList] = useState<any>()

  const [isLoading, setIsLoading] = useState(false)

  const getData = () => {

    let key = tenantKey
    if (state) {
      key = state
    }

    Toast.show({
      content: '加载中...',
      icon: 'loading',
      duration: 5000,
      maskClickable: false
    })
    const startTime = moment().startOf('day').format('YYYY-MM-DD')
    const endTime = moment().add(6, 'days').startOf('days').format('YYYY-MM-DD')
    setIsLoading(true)
    dispatch(
      getOnlineAppointmentDoctorCardList({
        tenantKey: key,
        starDate: startTime,
        endDate: endTime,
      })
    )
      .then(unwrapResult)
      .then((v) => {
        setDataList(v)
        setIsLoading(false)
        Toast.clear()
      })
  }

  useEffect(() => {
    if (tab == 0) {
      getData()
    }
  }, [tab])

  return (
    <>
      {
        isLoading ? (<></>) :
          (dataList?.length ?
            (<div className={styles.doctorCardContent}>
              <PullToRefresh
                onRefresh={async () => {
                  getData()
                }}
              >
                {dataList?.map((v: any) => {
                  return (
                    <Card className={styles.doctorCardContentCard} onClick={() => {
                      if (v?.status == 0) {
                        history.push({
                          pathname: '/appointmentTime',
                          state: {
                            doctorId: v?.doctorId,
                            tab: tab || '0',
                          },
                        })
                      }
                    }}>
                      <img
                        src={v?.sex == 2 ? girlHeadImg : boyHeadImg}
                        alt=''
                        className={styles.doctorCardContentImg}
                      ></img>
                      <div className={styles.doctorCardContentMain}>
                        <div className={styles.doctorCardContentName}>
                          {v?.doctorName || '-'}
                        </div>
                        <div className={styles.doctorCardContentType}>
                          {v?.appointmentDepartmentName || '-'}
                        </div>
                      </div>
                      {v?.status == 0 ? (
                        <div
                          className={styles.doctorCardContentButton}
                        >
                          预约
                        </div>
                      ) : v?.status == 1 ? (
                        <div className={styles.nodDctorCardContentButton}>已满</div>
                      ) : (
                        '-'
                      )}
                    </Card>
                  )
                })}
              </PullToRefresh>
            </div>)
            : (
              <div style={{ backgroundColor: '#fff', width: '100%', height: '100%' }}>
                <img src={noBg} style={{ width: '100%', height: '100%' }} alt="" />
              </div>
            )
          )
      }
    </>

  )
}
