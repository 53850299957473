/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-27 10:36:51
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-01 13:32:35
 */
import { NavBar, TabBar, Toast } from 'antd-mobile'
import { FC, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './DefaultLayout.module.css'
import { LeftOutline } from 'antd-mobile-icons'
import {
  getRouterName,
  renderNavBack,
  renderNavBar,
  renderTabBar,
} from '../models/router'
import {
  HomeIcon,
  MeIcon,
  ViolationIcon,
  AppoinmentIcon,
  AppoinmentActiveIcon
} from '../components/Icons/Icons'
import Router from '../app/Router'
import appoinmentOne from '../components/images/appoinmentOne.png'
import appoinmentTwo from '../components/images/appoinmentTwo.png'

export const DefaultLayout = () => {
  const history = useHistory()

  const location = useLocation()

  const { pathname } = location

  console.log(location, 'locationlocation')

  useEffect(() => {
    document.title = getRouterName(pathname)
  }, [pathname])

  const setRouteActive = (value: string) => {
    history.push(value)
  }

  const Bottom: FC = () => {
    const tabs = [
      {
        key: '/',
        title: '首页',
        icon: (
          <HomeIcon style={{ fill: pathname === '/' ? '#0EBC6A' : '#999' }} />
        ),
      },
      {
        key: '/onlineAppointment',
        title: '在线预约',
        icon: (
          // <AppoinmentIcon style={{ fill: pathname === '/onlineAppointment' ? '#0EBC6A' : '#999' }} />
          pathname === '/onlineAppointment' ?
            <AppoinmentActiveIcon />
            : <AppoinmentIcon />
        ),
      },
      {
        key: '/mine',
        title: '我的',
        icon: (
          <MeIcon style={{ fill: pathname === '/mine' ? '#0EBC6A' : '#999' }} />
        ),
      },
    ]

    return (
      <TabBar
        activeKey={pathname}
        onChange={(value) => {
          setRouteActive(value)
          document.title = getRouterName(pathname)
        }}
      >
        {tabs.map((item) => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} style={{ color: pathname === item.key ? '#0EBC6A' : '#999' }} />
        ))}
      </TabBar>
    )
  }
  return (
    <div className={styles.app}>
      {renderNavBar(pathname) && (
        <div className={styles.top}>
          <NavBar back={renderNavBack(pathname)} left={<LeftOutline />} onBack={() => history.go(-1)}>
            {getRouterName(pathname)}
          </NavBar>
        </div>
      )}
      <div className={styles.body}>
        <Router />
      </div>
      {renderTabBar(pathname) && (
        <div className={styles.bottom}>
          <Bottom />
        </div>
      )}
    </div>
  )
}
