/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:11:25
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-03 10:36:09
 */

export const getRouterName = (key: string) => {
  switch (key) {
    case '/':
    case '/home':
      return '首页'
    case '/clinicOverview':
      return '诊所概况'
    case '/doctorDetail':
      return '医生详情'
    case '/onlineAppointment':
      return '在线预约'
    case '/appointmentTime':
      return '在线预约'
    case '/appointmentTreatmentPeople':
      return '在线预约'
    case '/visitorsList':
      return '就诊人列表'
    case '/addPatientPeople':
      return '就诊人信息'
    case '/myVisitorsList':
      return '我的预约'
    case '/doctorList':
      return '医生列表'
    case '/patientDetail':
      return '就诊人详情'
    case '/coupons':
      return '优惠券'
    case '/mine':
      return '我的'
    case '/artical':
      return '健康科普'

    default:
      return '主页'
  }
}

export const renderNavBar = (key: string) => {
  switch (key) {
    case '/':
    case '/home':
      return false
    default:
      return true
  }
}

export const renderNavBack = (key: string) => {
  switch (key) {
    case '/':
    case '/home':
      return null
    default:
      return ''
  }
}

// 其余页面是否展示tabBar
export const renderTabBar = (key: string) => {
  switch (key) {
    case '/':
      return true
    case '/onlineAppointment':
      return true
    case '/mine':
      return true
    default:
      return false
  }
}
