export function getAppointmentStateName(appointmentState: any) {
  switch (appointmentState) {
    case 0:
      return '预约中'
    case 1:
      return '已挂号'
    case 2:
      return '已就诊'
    case 3:
      return '已取消'
    case 9:
      return '超时'
    case -1:
      return '超时取消'
    default:
      break
  }
}

export function getAppointmentStateColor(appointmentState: any) {
  switch (appointmentState) {
    case 0:
      return '#FFB145'
    case 1:
      return '#29BE78'
    case 2:
      return '#2A7CF6'
    case 3:
      return '#999999'
    case 9:
      return '#FF371F'
    case -1:
      return '#999999'
    default:
      break
  }
}
