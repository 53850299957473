/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-30 09:19:32
 * @LastEditors: linxi
 * @LastEditTime: 2021-11-30 11:19:25
 */
import React, { MouseEventHandler, ReactElement } from "react"
import styles from "./Icons.module.css"
import { ReactComponent as Home } from "./svg/Home.svg"
import { ReactComponent as Intelligent } from "./svg/Intelligent.svg"
import { ReactComponent as Appoinment } from "./svg/Appoinment.svg"
import { ReactComponent as AppointmentActive } from "./svg/AppointmentActive.svg"
import { ReactComponent as Violation } from "./svg/Violation.svg"
import { ReactComponent as Me } from "./svg/Me.svg"
import { ReactComponent as Disinfect } from "./svg/Disinfect.svg"
import { ReactComponent as Consulting } from "./svg/Consulting.svg"
import { ReactComponent as IntelligentInline } from "./svg/IntelligentInline.svg"
import { ReactComponent as IntelligentResult } from "./svg/IntelligentResult.svg"
import { ReactComponent as Medicalwaste } from "./svg/Medicalwaste.svg"
import { ReactComponent as Triage } from "./svg/Triage.svg"

type IconMode = "large" | "normal" | "small"

interface IconProps {
  mode?: IconMode
  icon?: string
  className?: string
  onClick?: MouseEventHandler
  style?: React.CSSProperties
}

function getStyleName(props: IconProps): string {
  if (props.className && props.className !== "ant-menu-item-icon") {
    return props.className
  }
  if (props.mode === "large") {
    return styles.large
  }
  if (props.mode === "small") {
    return styles.small
  }
  return styles.normal
}

export const HomeIcon = (props: IconProps): ReactElement => (
  <Home
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const MeIcon = (props: IconProps): ReactElement => (
  <Me
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const ViolationIcon = (props: IconProps): ReactElement => (
  <Violation
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const IntelligentIcon = (props: IconProps): ReactElement => (
  <Intelligent
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const DisinfectIcon = (props: IconProps): ReactElement => (
  <Disinfect
    className={getStyleName(props)}
    style={{ ...props.style, width: "20px", height: "20px" }}
    onClick={props.onClick}
  />
)
export const ConsultingIcon = (props: IconProps): ReactElement => (
  <Consulting
    className={getStyleName(props)}
    style={{ ...props.style, width: "20px", height: "20px" }}
    onClick={props.onClick}
  />
)
export const IntelligentInlineIcon = (props: IconProps): ReactElement => (
  <IntelligentInline
    className={getStyleName(props)}
    style={{ ...props.style }}
    onClick={props.onClick}
  />
)
export const IntelligentResultIcon = (props: IconProps): ReactElement => (
  <IntelligentResult
    className={getStyleName(props)}
    style={{ ...props.style, width: "20px", height: "20px" }}
    onClick={props.onClick}
  />
)
export const MedicalwasteIcon = (props: IconProps): ReactElement => (
  <Medicalwaste
    className={getStyleName(props)}
    style={{ ...props.style, width: "20px", height: "20px" }}
    onClick={props.onClick}
  />
)
export const TriageIcon = (props: IconProps): ReactElement => (
  <Triage
    className={getStyleName(props)}
    style={{ ...props.style, width: "20px", height: "20px" }}
    onClick={props.onClick}
  />
)
export const AppoinmentIcon = (props: IconProps): ReactElement => (
  <Appoinment
    className={getStyleName(props)}
    style={{ ...props.style, width: "20px", height: "20px" }}
    onClick={props.onClick}
  />
)
export const AppoinmentActiveIcon = (props: IconProps): ReactElement => (
  <AppointmentActive
    className={getStyleName(props)}
    style={{ ...props.style, width: "20px", height: "20px" }}
    onClick={props.onClick}
  />
)
