import { Tabs, Dialog, DotLoading, Toast } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../app/store'
import styles from './coupons.module.css'
import couponsOver from '../../components/images/couponsOver.png'
import couponsUsed from '../../components/images/couponsUsed.png'
import couponsUnuse from '../../components/images/couponsUnuse.png'
import noBg from '../../components/images/noBg.svg'
import { _getCoupons } from './coupon'
import React, { useEffect, useState } from 'react'
import { selectTenantKey } from '../../app/appSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { Share } from "../../components/Share/share";
import { LeftOutline } from 'antd-mobile-icons'
import { useHistory } from 'react-router-dom'

export const Coupons = () => {

    const dispatch = useDispatch<AppDispatch>()

    const history = useHistory()

    const [activeIndex, setActiveIndex] = useState('1')

    const tenantKey = useSelector(selectTenantKey)

    const [couponsList, setConponsList] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        Toast.show({
            content: '加载中...',
            icon: 'loading',
            duration: 5000,
            maskClickable: false
        })
        setIsLoading(true)
        dispatch(_getCoupons({ useStatus: activeIndex, patientId: localStorage.getItem('patientId') || '', tenantKey }))
            .then(unwrapResult)
            .then((res: any) => {
                setConponsList(res)
                setIsLoading(false)
                Toast.clear()
            })
    }, [activeIndex])

    return (
        <div className={styles.coupons}>
            <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
            <Share title={'优惠券'} param={''} />
            <Tabs activeKey={activeIndex} onChange={key => {
                setActiveIndex(key)
            }}>
                <Tabs.Tab title='未使用' key='1'>
                    {
                        isLoading ? (<></>) : couponsList?.length ? (
                            <div className={styles.couponsList}>
                                {couponsList.map((item: any, index: number) => {
                                    return <div className={styles.couponItem} onClick={() =>
                                        Dialog.show({
                                            closeOnAction: true,
                                            content: <div className={styles.detail}>
                                                <div className={styles.detailType}>{item.couponsName}</div>
                                                <div className={styles.detailIssue}>{item.category === 3 ? '体验券' : item.discount}{item.category === 1 ? '折' : item.category === 2 ? '元' : item.category === 3 ? '' : '-'}</div>
                                                <div className={styles.detailTitle}>优惠券类型</div>
                                                <div className={styles.detailContent}>{item.category === 1 ? '折扣券' : item.category === 2 ? '抵扣券' : item.category === 3 ? '体验券' : '-'}</div>
                                                <div className={styles.detailTitle}>适用时间</div>
                                                <div className={styles.detailContent}>{item.createTime.split(' ')[0]} ～ {item.expirationTime.split(' ')[0]} </div>
                                                <div className={styles.detailTitle}>适用范围</div>
                                                <div className={styles.detailContent}>{item.itemName}</div>
                                                <div className={styles.know} onClick={() => {
                                                    Dialog.clear()
                                                }}>我知道了</div>
                                            </div>,
                                        })
                                    }>
                                        <div className={styles.couponItemLeft}>
                                            <div className={styles.couponItemLeftTop}>
                                                <span className={item.category === 3 ? styles.freeCoupons : styles.couponItemLeftTopValue}>{item.category === 3 ? '体验券' : item.discount}</span>
                                                <span>{item.category === 1 ? '折' : item.category === 2 ? '元' : item.category === 3 ? '' : '-'}</span>
                                            </div>
                                            {/* <div className={styles.couponItemLeftBottom}>满100可用</div> */}
                                        </div>
                                        <div className={styles.couponItemRight}>
                                            <div className={styles.couponItemRightTop}>{item.couponsName}</div>
                                            <div className={styles.couponItemRightBottom}>{item.expirationTime} 前可用</div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        ) : (
                            <img src={noBg} style={{ width: '100%', height: '100%' }} alt="" />
                        )
                    }
                </Tabs.Tab>
                <Tabs.Tab title='已使用' key='2'>
                    {
                        isLoading ? (<></>) : couponsList?.length ? (
                            <div className={styles.couponsList}>
                                {couponsList.map((item: any, index: number) => {
                                    return <div className={styles.couponItem} style={{ background: 'linear-gradient(-45.43deg, rgba(234, 222, 208, 0.16), rgba(222, 192, 164, 0.3))' }} onClick={() =>
                                        Dialog.show({
                                            closeOnAction: true,
                                            content: <div className={styles.detail}>
                                                <div className={styles.detailType}>{item.couponsName}</div>
                                                <div className={styles.detailIssue}>{item.category === 3 ? '体验券' : item.discount}{item.category === 1 ? '折' : item.category === 2 ? '元' : item.category === 3 ? '' : '-'}</div>
                                                <div className={styles.detailTitle}>优惠券类型</div>
                                                <div className={styles.detailContent}>{item.category === 1 ? '折扣券' : item.category === 2 ? '抵扣券' : item.category === 3 ? '体验券' : '-'}</div>
                                                <div className={styles.detailTitle}>适用时间</div>
                                                <div className={styles.detailContent}>{item.createTime.split(' ')[0]} ～ {item.expirationTime.split(' ')[0]} </div>
                                                <div className={styles.detailTitle}>适用范围</div>
                                                <div className={styles.detailContent}>{item.itemName}</div>
                                                <div className={styles.know} onClick={() => {
                                                    Dialog.clear()
                                                }}>我知道了</div>
                                            </div>,
                                        })
                                    }>
                                        <div className={styles.couponItemLeft}>
                                            <div className={styles.couponItemLeftTop}>
                                                <span className={styles.couponItemLeftTopValue}>{item.category === 3 ? '体验券' : item.discount}</span>
                                                <span>{item.category === 1 ? '折' : item.category === 2 ? '元' : item.category === 3 ? '' : '-'}</span>
                                            </div>
                                            {/* <div className={styles.couponItemLeftBottom}>满100可用</div> */}
                                        </div>
                                        <div className={styles.couponItemRight}>
                                            <div className={styles.couponItemRightTop}>{item.couponsName}</div>
                                            <div className={styles.couponItemRightBottom}>{item.expirationTime} 前可用</div>
                                        </div>
                                        <div className={styles.typeIcon}>
                                            <img src={couponsUsed} style={{ width: '100%', height: '100%' }} alt="" />
                                        </div>
                                    </div>
                                })}
                            </div>
                        ) : (
                            <img src={noBg} style={{ width: '100%', height: '100%' }} alt="" />
                        )
                    }
                </Tabs.Tab>
                <Tabs.Tab title='不可用' key='3'>
                    {
                        isLoading ? (<></>) : couponsList?.length ? (
                            <div className={styles.couponsList}>
                                {couponsList.map((item: any, index: number) => {
                                    return <div className={styles.couponItem} style={{ background: 'linear-gradient(-45.43deg, rgba(234, 222, 208, 0.16), rgba(222, 192, 164, 0.3))' }} onClick={() =>
                                        Dialog.show({
                                            closeOnAction: true,
                                            content: <div className={styles.detail}>
                                                <div className={styles.detailType}>{item.couponsName}</div>
                                                <div className={styles.detailIssue}>{item.category === 3 ? '体验券' : item.discount}{item.category === 1 ? '折' : item.category === 2 ? '元' : item.category === 3 ? '' : '-'}</div>
                                                <div className={styles.detailTitle}>优惠券类型</div>
                                                <div className={styles.detailContent}>{item.category === 1 ? '折扣券' : item.category === 2 ? '抵扣券' : item.category === 3 ? '体验券' : '-'}</div>
                                                <div className={styles.detailTitle}>适用时间</div>
                                                <div className={styles.detailContent}>{item.createTime.split(' ')[0]} ～ {item.expirationTime.split(' ')[0]} </div>
                                                <div className={styles.detailTitle}>适用范围</div>
                                                <div className={styles.detailContent}>{item.itemName}</div>
                                                <div className={styles.know} onClick={() => {
                                                    Dialog.clear()
                                                }}>我知道了</div>
                                            </div>,
                                        })
                                    }>
                                        <div className={styles.couponItemLeft}>
                                            <div className={styles.couponItemLeftTop}>
                                                <span className={styles.couponItemLeftTopValue}>{item.category === 3 ? '体验券' : item.discount}</span>
                                                <span>{item.category === 1 ? '折' : item.category === 2 ? '元' : item.category === 3 ? '' : '-'}</span>
                                            </div>
                                        </div>
                                        <div className={styles.couponItemRight}>
                                            <div className={styles.couponItemRightTop}>{item.couponsName}</div>
                                            <div className={styles.couponItemRightBottom}>{item.expirationTime} 前可用</div>
                                        </div>
                                        <div className={styles.typeIcon}>
                                            <img src={couponsUnuse} style={{ width: '100%', height: '100%' }} alt="" />
                                        </div>
                                    </div>
                                })}
                            </div>
                        ) : (
                            <img src={noBg} style={{ width: '100%', height: '100%' }} alt="" />
                        )
                    }

                </Tabs.Tab>
            </Tabs>
        </div>
    )
}