/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 11:45:11
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-14 13:27:31
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { DotLoading, List, SearchBar, Tabs } from 'antd-mobile'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {selectAppId, selectOpenId, selectTenantKey} from '../../app/appSlice'

import { AppDispatch } from '../../app/store'
import { getPatientList } from '../visitorsList/visitorsListSlice'
import styles from './visitorsList.module.css'
import { VisitorsListCard } from './visitorsListCard'
import {Share} from "../../components/Share/share";
import { LeftOutline } from 'antd-mobile-icons'

export const VisitorsList = () => {
  const history = useHistory()

  const dispatch = useDispatch<AppDispatch>()

  const detailData: any = history.location.state //从appointmentTreatmentPeople页面中的新增过来的 ，列表可以点击选择联系人
  console.log(detailData, 'detailData')

  const [queries, setQueries] = useState<any>({
    current: 0,
    size: 10,
  })

  const [length, setLength] = useState<any>()

  const [nextCurrent, setNextCurrent] = useState(0)

  const [flag, setFlag] = useState<any>()

  const [data, setData] = useState<any>([])

  const appid = useSelector(selectAppId)

  const tenantKey = useSelector(selectTenantKey)

  const openid = useSelector(selectOpenId)

  useEffect(() => {
    if (detailData) {
      localStorage.setItem('detailData', JSON.stringify(detailData))
    }
  }, [detailData])

  console.log(queries, 'queries')

  useEffect(() => {
    if (queries?.current != 0) {
      dispatch(
        getPatientList({
          ...queries,
          tenantKey: tenantKey,
          openid: openid,
        })
      )
        .then(unwrapResult)
        .then((value: any) => {
          setFlag(undefined)
          setNextCurrent(value?.current)
          setLength(value?.records?.length)
          const state = value?.records?.some((v:any) => v.isDefault == 1)
          if(state) {
            sessionStorage.removeItem('patientListId')
          }
          if (value?.current === 1) {
            setData(value?.records)
          } else {
            if (queries?.current > nextCurrent) {
              setData([...data, ...value?.records])
            }
          }
        })
    }
  }, [dispatch, queries])

  useEffect(() => {
    if (flag === true) {
      if (length < 10) {
        return
      } else {
        setQueries({
          ...queries,
          current: queries?.current + 1,
        })
      }
    }
  }, [flag, length])

  console.log(detailData, 'tag')

  return (
    <div className={styles.visitorsList}>
      <div className={styles.backIconBox}>
        <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
      </div>
      <Share title={'首页'} param={''}/>
      <VisitorsListCard
        detailData={detailData}
        length={length}
        getMore={(v) => {
          setFlag(v)
        }}
        data={data}
        refresh={() => {
          setQueries({
            size: 10,
            current: 1,
          })
        }}
      />
      <div
        className={styles.bottomButton}
        onClick={() => {
          history.push({
            pathname: '/addPatientPeople',
            state: detailData,
          })
        }}
      >
        <div className={styles.bottomButtonText}>新增就诊人</div>
      </div>
    </div>
  )
}
