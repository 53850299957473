import {getWechatJsSdkSignature} from "../../features/home/HomeSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import wx from "weixin-js-sdk";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../app/store";
import {selectAppId, selectTenantKey} from "../../app/appSlice";
import React, {ReactNode, useEffect} from "react";

interface shartProps {
    title: string
    param: string
}

export const Share : React.FC<shartProps> = ({
   title,
   param
}) => {

    const dispatch = useDispatch<AppDispatch>()

    const tenantKey = useSelector(selectTenantKey)

    const appId = useSelector(selectAppId)

    const data = {
        currUrl: window.location.href.split('#')[0],
        appid: appId,
    }

    //let linkUrl = 'https://patienth5.develop.hydhis.com'
    let linkUrl = 'https://patienth5.hydhis.cn'

    const tenantName = localStorage.getItem('tenantName')

    useEffect(() => {

        let subAddress = ''
        if (title !== '首页') {
            subAddress = window.location.href.split(linkUrl)[1]
        }

        dispatch(getWechatJsSdkSignature(data))
            .then(unwrapResult)
            .then((res: any) => {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                    appId: appId, // 必填，公众号的唯一标识
                    timestamp: res.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.noncestr, // 必填，生成签名的随机串
                    signature: res.signature, // 必填，签名
                    jsApiList: ['openLocation', 'updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的 JS 接口列表
                })

                wx.ready(function () {   //需在用户可能点击分享按钮前就先调用

                    wx.updateAppMessageShareData({
                        title: tenantName + (title ? ' - ' + title : ''), // 分享标题
                        desc: title ? title : '', // 分享描述
                        link: linkUrl + '?share=true&subAddress='+subAddress+'&state=' + tenantKey + param, // 分享链接，
                        imgUrl: '1111', // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    })

                    wx.updateTimelineShareData({
                        title: tenantName + (title ? ' - ' + title : ''), // 分享标题
                        desc: title ? title : '', // 分享描述
                        link: linkUrl + '?share=true&subAddress='+subAddress+'&state=' + tenantKey + param, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: '1111', // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    })
                });
            })
    },[])

    return (
        <></>
    )
}