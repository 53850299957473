/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 17:27:39
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-09 15:58:21
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { send } from '../../app/appSlice'
import { AppThunkApi, RootState } from '../../app/store'
import {
  getOpenIdParams,
  _getOpenIdByCode,
  getWechatJsSdkSignatureParams,
  _getWechatJsSdkSignature,
  homeDetail,
  doctorList,
  _getDeafaultPatient,
  _getDeafaultPatientCode,
  _updateDeafaultPatient
} from '../../services/home'

// 首页数据
export const getHomeDetail = createAsyncThunk<void, any, AppThunkApi>(
  'home/page',
  async (params, api) => {
    return send(homeDetail(params), api)
  }
)

// 医生团队
export const getDoctorList = createAsyncThunk<void, any, AppThunkApi>(
  'home/DoctorDetail',
  async (params, api) => {
    return send(doctorList(params), api)
  }
)

// 通过微信跳转Code获取粉丝用户ID
export const getOpenIdByCode = createAsyncThunk<any, getOpenIdParams, AppThunkApi>(
  'WeChatThirdPartyApi/getOpenIdByCode',
  async (params, api) => {
    return send(_getOpenIdByCode(params), api)
  }
)

// 获取微信jssdk签名
export const getWechatJsSdkSignature = createAsyncThunk<any, getWechatJsSdkSignatureParams, AppThunkApi>(
  'WeChatThirdPartyApi/getWechatJsSdkSignature',
  async (params, api) => {
    return send(_getWechatJsSdkSignature(params), api)
  }
)

// 获取微信jssdk签名
export const getDefaultPatient = createAsyncThunk<any, { openid: string, tenantKey: string }, AppThunkApi>(
  'WeChatThirdPartyApi/getDefaultPatient',
  async (params, api) => {
    return send(_getDeafaultPatient(params), api)
  }
)

// 获取微信jssdk签名
export const getDefaultPatientCode = createAsyncThunk<any, { content: string }, AppThunkApi>(
  'WeChatThirdPartyApi/getDefaultPatient',
  async (params, api) => {
    return send(_getDeafaultPatientCode(params), api)
  }
)

// 更新就诊人
export const updateDeafaultPatient = createAsyncThunk<any, { tenantKey: string; id: string, openid: string }, AppThunkApi>(
  'WeChatThirdPartyApi/updateDeafaultPatient',
  async (params, api) => {
    return send(_updateDeafaultPatient(params), api)
  }
)