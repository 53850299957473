/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:33:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-01 11:19:46
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { DotLoading, Form, Input, TextArea, Toast } from 'antd-mobile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './appointmentTreatmentPeople.module.css'
import rightIconArrow from '../../components/images/rightIconArrow.png'
import { getSexName } from '../../models/gender'
import {
  getAppointmentTreatmentPeopleSubmit,
  getDefaultPeopleList,
} from './appointmentTreatmentPeopleSlice'
import { selectAppId, selectOpenId, selectTenantKey } from '../../app/appSlice'
import { Share } from "../../components/Share/share";
import { LeftOutline } from 'antd-mobile-icons'

export const AppointmentTreatmentPeople = () => {
  const dispatch = useDispatch<AppDispatch>()

  const history: any = useHistory()

  const [form] = Form.useForm()

  const detailData: any = localStorage.getItem('detailData')

  const data: any = history.location.state || JSON.parse(detailData)

  console.log(JSON.parse(detailData), 'JSON.parse(detailData)')

  const appid = useSelector(selectAppId)

  const tenantKey = useSelector(selectTenantKey)

  const openid = useSelector(selectOpenId)

  const userDatas: any = history.location.state?.userData

  const [appointmentItem, setAppointmentItem] = useState<any>()

  const [userData, setUserData] = useState<any>()

  console.log(userData, userDatas, 'userDatas')

  const [appointmentComment, setAppointmentComment] = useState<any>()

  const params = {
    tag: 2,
    innerDepartmentId: data?.innerDepartmentId,
    realname: data?.realname,
    noSETime: data?.noSETime,
    allTime: data?.allTime,
    outpatientDepartmentName: data?.outpatientDepartmentName,
    startTime: data?.startTime,
    endTime: data?.endTime,
    appointmentDoctorId: data?.appointmentDoctorId,
    time: data?.time,
    scheduleId: data?.scheduleId,
    appointmentItem: appointmentItem ? appointmentItem : data?.appointmentItem,
    appointmentComment: appointmentComment
      ? appointmentComment
      : data?.appointmentComment,
  }

  useEffect(() => {
    form.setFieldsValue({
      realname: data?.realname,
      appointmentDate: data?.allTime,
      outpatientDepartmentName: data?.outpatientDepartmentName,
      appointmentItem: appointmentItem
        ? appointmentItem
        : data?.appointmentItem,
    })
  }, [])

  useEffect(() => {
    dispatch(
      getDefaultPeopleList({
        tenantKey: tenantKey,
        openid: openid,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        if (userDatas) {
          setUserData({ ...userDatas })
        } else {
          setUserData({
            name: v.name,
            sex: v.sex,
            ageYear: v.ageYear,
            ageMonth: v?.ageMonth,
            phone: v.phone,
            id: v.id,
            birthday: v?.birthday,
          })
        }
      })
  }, [])

  return (
    <div className={styles.appointmentTreatmentPeopleContent}>
      <div className={styles.backIconBox}>
        <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
      </div>
      <Share title={'首页'} param={''} />
      <div className={styles.cardOne}>
        <Form
          layout='horizontal'
          form={form}
          onFinish={(value) => {
            console.log(data, 'value')
            const params = {
              appointmentDate: moment(data?.noSETime).format('YYYY-MM-DD'),
              realname: data?.realname,
              appointmentDepartmentId: data?.innerDepartmentId,
              outpatientDepartmentName: data?.outpatientDepartmentName,
              appointmentDoctorId: data?.appointmentDoctorId,
              appointmentStartTime: data?.startTime,
              appointmentEndTime: data?.endTime,
              appointmentItem: value.appointmentItem,
              appointmentComment: appointmentComment,
              scheduleId: data?.scheduleId,
              tenantKey: tenantKey,
              openid: openid,
              ageMonth: data?.userData?.ageMonth || userData?.ageMonth,
              ageYear: data?.userData?.ageYear || userData?.ageYear,
              birthday: data?.userData?.birthday
                ? moment(data?.userData?.birthday).format('YYYY-MM-DD')
                : moment(userData?.birthday).format('YYYY-MM-DD'),
              name: data?.userData?.name || userData?.name,
              phone: data?.userData?.phone || userData?.phone,
              sex: data?.userData?.sex || userData?.sex,
              appointmentType: 5,
              // patientId: data?.userData?.id,
            }
            dispatch(getAppointmentTreatmentPeopleSubmit(params))
              .then(unwrapResult)
              .then((v) => {
                Toast.show({
                  icon: 'success',
                  duration: 1000,
                  content: '操作成功',
                  afterClose: () => {
                    history.replace({
                      pathname: '/myVisitorsList',
                    })
                    // history.go(-1)
                  },
                })
              })
            // .catch((v) => {
            //   Toast.show({
            //     icon: 'fail',
            //     content: v.message,
            //   })
            // })
          }}
        >
          <Form.Item name='outpatientDepartmentName' label='预约科室'>
            <Input placeholder='请输入' readOnly />
          </Form.Item>
          <Form.Item name='realname' label='预约医生'>
            <Input placeholder='请输入' readOnly />
          </Form.Item>
          <Form.Item name='appointmentDate' label='预约时间'>
            <Input placeholder='请输入' readOnly style={{ color: 'red' }} />
          </Form.Item>
          <Form.Item name='appointmentItem' label='预约项目'>
            <Input
              maxLength={40}
              value={appointmentItem ? appointmentItem : data?.appointmentItem}
              onChange={(v) => {
                setAppointmentItem(v)
              }}
              placeholder='请输入'
            />
          </Form.Item>
        </Form>

        <div className={styles.treatmentPeopleContent}>
          <div className={styles.treatmentPeople}>
            <div style={{ paddingLeft: '16px', width: '20%' }}>
              就诊人<span style={{ color: 'red' }}>*</span>
            </div>
            {JSON.stringify(userData) === '{}' || userData == undefined ? (
              <div className={styles.rightContent}></div>
            ) : (
              <div
                className={styles.rightContent}
                onClick={() => {
                  history.push({
                    pathname: '/visitorsList',
                    state: {
                      ...params,
                    },
                  })
                }}
              >
                <span style={{ fontSize: '15px', color: '#CCCCCC' }}>选择</span>
                <img
                  src={rightIconArrow}
                  alt=''
                  style={{ width: '18px', height: '18px', color: '#CCCCCC' }}
                ></img>
              </div>
            )}
          </div>
          {JSON.stringify(userData) === '{}' || userData == undefined ? (
            <div className={styles.noUserMessage}>
              <div
                onClick={() => {
                  history.push({
                    pathname: '/visitorsList',
                    state: {
                      ...params,
                    },
                  })
                }}
              >
                <span>暂无就诊人，请先去</span>
                <span
                  style={{
                    color: '#29BE78',
                    borderBottom: '1px solid #29BE78',
                  }}
                >
                  新增
                </span>
              </div>
            </div>
          ) : (
            <div className={styles.userMessage}>
              <div style={{ marginBottom: '10px' }}>
                {userData?.name}（{getSexName(userData?.sex)}/
                {userData?.ageYear > 0
                  ? userData?.ageYear + '岁'
                  : userData?.ageMonth + '个月'}
                ）
              </div>
              <div>联系方式：{userData?.phone}</div>
            </div>
          )}
        </div>
        <div className={styles.treatmentPeopleContent}>
          <div className={styles.treatmentPeople}>
            <div style={{ paddingLeft: '16px' }}>预约备注</div>
          </div>
          <div className={styles.userMessage}>
            <TextArea
              autoSize
              maxLength={100}
              value={
                appointmentComment
                  ? appointmentComment
                  : data?.appointmentComment
              }
              placeholder='选填，请输入患者就诊事项'
              onChange={(val) => {
                setAppointmentComment(val)
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={styles.bottomButton}
        onClick={() => {
          // 从就诊人列表选择过来的
          console.log(userData, 'userData')
          Toast.show({
            content: '加载中...',
            icon: 'loading',
            duration: 3000,
            maskClickable: false
          })
          const submitData = {
            ageMonth: userData?.ageMonth?.toString(),
            ageYear: userData?.ageYear?.toString(),
            id: userData?.id?.toString(),
            name: userData?.name?.toString(),
            phone: userData?.phone?.toString(),
            sex: userData?.sex?.toString(),
          }
          if (
            !submitData?.ageMonth ||
            !submitData?.ageYear ||
            !submitData?.id ||
            !submitData?.name ||
            !submitData?.phone ||
            !submitData?.sex
          ) {
            Toast.show({
              content: '请选择就诊人',
              afterClose: () => { },
            })
          } else {
            form.submit()
          }
        }}
      >
        <div className={styles.bottomButtonText}>确认预约</div>
      </div>
    </div>
  )
}
