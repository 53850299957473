/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-12-02 13:56:01
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-02 16:37:16
 */
import { Switch, Route, Redirect } from 'react-router'
import { AddPatientPeople } from '../features/addPatientPeople/addPatientPeople'
import { AppointmentTime } from '../features/appointmentTime/appointmentTime'
import { AppointmentTreatmentPeople } from '../features/appointmentTreatmentPeople/appointmentTreatmentPeople'
import { ClinicOverview } from '../features/clinicOverview/ClinicOverview'
import { DoctorDetail } from '../features/doctorDetail/doctorDetail'
import { Home } from '../features/home/Home'
import { MyVisitorsList } from '../features/myVisitorsList/myVisitorsList'
import { OnlineAppointment } from '../features/onlineAppointment/onlineAppointment'
import { VisitorsList } from '../features/visitorsList/visitorsList'
import { PatientDetail } from '../features/patientDetail/patientDetail'
import { DoctorList } from '../features/doctorList/doctorList'
import { Mine } from '../features/mine/mine'
import { Coupons } from '../features/coupons/coupons'
import { Artical } from '../features/articalDetail/artical'
import { Setting } from '../features/setting/setting'


const Router = () => {
  return (
    <Switch>
      <Route exact path='/home'>
        {/* 首页 */}
        <Home />
      </Route>
      <Route exact path='/'>
        {/* 首页 */}
        <Home />
      </Route>
      <Route exact path='/clinicOverview'>
        {/* 诊所概况 */}
        <ClinicOverview />
      </Route>
      <Route exact path='/doctorDetail'>
        {/* 医生详情 */}
        <DoctorDetail />
      </Route>
      <Route exact path='/doctorList'>
        {/* 医生列表 */}
        <DoctorList />
      </Route>
      <Route exact path='/onlineAppointment'>
        {/* 在线预约 */}
        <OnlineAppointment />
      </Route>
      <Route exact path='/appointmentTime'>
        {/* 在线预约 */}
        <AppointmentTime />
      </Route>
      <Route exact path='/appointmentTreatmentPeople'>
        {/* 预约就诊人 */}
        <AppointmentTreatmentPeople />
      </Route>
      <Route exact path='/visitorsList'>
        {/* 就诊人列表 */}
        <VisitorsList />
      </Route>
      <Route exact path='/addPatientPeople'>
        {/* 就诊人信息 */}
        <AddPatientPeople />
      </Route>
      <Route exact path='/myVisitorsList'>
        {/* 就诊人信息 */}
        <MyVisitorsList />
      </Route>
      <Route exact path='/patientDetail'>
        {/* 就诊人详情 */}
        <PatientDetail />
      </Route>
      <Route exact path='/mine'>
        {/* 我的 */}
        <Mine />
      </Route>
      <Route exact path='/coupons'>
        {/* 优惠券 */}
        <Coupons />
      </Route>
      <Route exact path='/artical'>
        {/* 文章详情 */}
        <Artical />
      </Route>
      <Route exact path='/setting'>
        {/* 设置 */}
        <Setting />
      </Route>
      <Redirect from='/' to='/home'></Redirect>
    </Switch>
  )
}

export default Router
