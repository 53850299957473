/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-19 09:59:56
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-19 10:10:58
 */
export class ServiceError extends Error {
  code: number;

  constructor(code: number, message: any) {
    super(message);
    this.code = code;
  }
}
